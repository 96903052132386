import React from 'react'
import { Button, Icon } from '@blueprintjs/core'
import styled from 'styled-components'

interface PurchaseConfirmationButtonsProps {
  onConfirm: () => void
  onCancel: () => void
  loading?: boolean
  confirmButtonLabel?: string
  cancelButtonLabel?: string
}

const PurchaseConfirmationButtons: React.FC<PurchaseConfirmationButtonsProps> = ({
  onConfirm,
  onCancel,
  loading,
  confirmButtonLabel = 'Sim',
  cancelButtonLabel = 'Não',
}) => {
  return (
    <ButtonContainer>
      <StyledButton
        type="button"
        className="bp3-button bp3-intent-success"
        onClick={onConfirm}
        tabIndex={1}
        loading={loading}
      >
        {confirmButtonLabel}
        <Icon style={{ marginRight: '2px', marginLeft: '8px' }} icon="tick" />
      </StyledButton>
      <StyledButton rightIcon="cross" intent="danger" tabIndex={1} onClick={onCancel}>
        {cancelButtonLabel}
      </StyledButton>
    </ButtonContainer>
  )
}

export default PurchaseConfirmationButtons

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

const StyledButton = styled(Button)`
  margin-right: 16px;
  margin-top: 16px;
`
