import React, { useEffect, useState } from 'react'
import { SectionContent } from './SectionContent'
import styled from 'styled-components'
import { blue } from '../../../ui/utils/_colors'
import { startCountdown } from '../../../utils/timer.utils'
import { DateUtils } from '../../../common/date-utils'

interface Props {
  analystVerificationAllowedTimestamp?: string
  onAnalystRequest: () => void
}

const WaitTimeForAnalystInLivenessProcess = ({
  analystVerificationAllowedTimestamp,
  onAnalystRequest,
}: Props) => {
  const [timeLeftInSeconds, setTimeLeftInSeconds] = useState<number>(0)

  useEffect(() => {
    if (analystVerificationAllowedTimestamp) {
      const diffInSeconds = DateUtils.getDiffInSeconds(analystVerificationAllowedTimestamp)
      setTimeLeftInSeconds(diffInSeconds)

      const clearTimer = startCountdown(diffInSeconds, setTimeLeftInSeconds)
      return () => clearTimer()
    }
  }, [analystVerificationAllowedTimestamp])

  const text =
    timeLeftInSeconds > 0 ? (
      `Você pode optar pela validação por telefone após o tempo indicado: ${DateUtils.formatTimeToCountdown(
        timeLeftInSeconds
      )}`
    ) : (
      <ActionText onClick={onAnalystRequest}>Solicitar validação por telefone</ActionText>
    )

  if (!analystVerificationAllowedTimestamp) {
    return null
  }

  return (
    <div>
      <Section>
        <SectionContent
          title="Está tendo problema para finalizar a validação facial?"
          text={text}
        />
      </Section>
    </div>
  )
}

export default WaitTimeForAnalystInLivenessProcess

const Section = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
`

const ActionText = styled.span`
  color: ${blue.light};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter';

  cursor: pointer;
`
