import React from 'react'
import { H5 } from '@blueprintjs/core'
import { formatBRL } from '../../cash-register/common/formatBRL'
import { SourceProduct } from '../../services/bff/coordinator/dto/Contracts.dto'

import PurchaseConfirmationButtons from './PurchaseConfirmationButtons'
import ImeiVerification from './ImeiVerification'
import styled from 'styled-components'

interface PurchaseConfirmationDetailsProps {
  purchaseValue: number
  numberOfInstallments: number
  installmentsValue: number
  sourceProduct: SourceProduct
  contractId: string
  productId?: string
  transactionalCreditEvaluationId?: string
  nextStep: () => void
  cancelPurchase: () => void
}

const PurchaseConfirmationDetails: React.FC<PurchaseConfirmationDetailsProps> = ({
  purchaseValue,
  numberOfInstallments,
  installmentsValue,
  sourceProduct,
  contractId,
  productId,
  transactionalCreditEvaluationId,
  cancelPurchase,
  nextStep,
}) => {
  return (
    <Container>
      <H5>
        {`Confirmar compra com total de ${formatBRL(
          purchaseValue
        )} em ${numberOfInstallments} parcelas de ${formatBRL(installmentsValue)}?`}
      </H5>

      {sourceProduct === SourceProduct.SMARTPHONE_SALE ? (
        <ImeiVerification
          contractId={contractId}
          productId={productId!}
          transactionalCreditEvaluationId={transactionalCreditEvaluationId!}
          onCancel={cancelPurchase}
          nextStep={nextStep}
        />
      ) : (
        <PurchaseConfirmationButtons onConfirm={nextStep} onCancel={cancelPurchase} />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  max-width: 500px;
`

export default PurchaseConfirmationDetails
