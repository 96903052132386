import React, { useState, useEffect } from 'react'
import { H5, Button, Label } from '@blueprintjs/core'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { formItemStyle } from '../../cash-register/styles'
import { isImeiValid } from '../../utils/imei.utils'
import PurchaseConfirmationButtons from './PurchaseConfirmationButtons'
import { useDispatch } from 'react-redux'
import SmartphoneSaleSliceReducer, {
  SmartphoneIMEIVerificationStep,
} from '../../redux/reducers/smartphone-sale/purchase-verification.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { toaster } from '../../App'
import { SmartphoneSaleIMEIVerificationError } from '../../common/ErrorStrings'

interface ImeiVerificationProps {
  contractId: string
  productId: string
  transactionalCreditEvaluationId: string
  onCancel: () => void
  nextStep: () => void
}

const ImeiVerification: React.FC<ImeiVerificationProps> = ({
  contractId,
  productId,
  transactionalCreditEvaluationId,
  onCancel,
  nextStep,
}) => {
  const dispatch = useDispatch()
  const [smartphoneIMEI, setSmartphoneIMEI] = useState<string>('')
  const {
    isVerifyingSmartphoneIMEILoading,
    verifySmartphoneIMEIError,
    smartphoneIMEIVerificationStep,
  } = useTypedSelector((state) => ({
    isVerifyingSmartphoneIMEILoading: state.smartphoneSale.isVerifyingSmartphoneIMEILoading,
    verifySmartphoneIMEIError: state.smartphoneSale.verifySmartphoneIMEIError,
    smartphoneIMEIVerificationStep: state.smartphoneSale.smartphoneIMEIVerificationStep,
  }))

  useEffect(() => {
    if (verifySmartphoneIMEIError) {
      toaster.showErrorToast(verifySmartphoneIMEIError)
    }
  }, [verifySmartphoneIMEIError])

  useEffect(() => {
    if (smartphoneIMEIVerificationStep === SmartphoneIMEIVerificationStep.VERIFIED) {
      nextStep()
    }
  }, [smartphoneIMEIVerificationStep])

  const verifySmartphoneIMEI = () => {
    if (!isImeiValid(smartphoneIMEI)) {
      toaster.showErrorToast(SmartphoneSaleIMEIVerificationError.INVALID_IMEI)
      return
    }

    dispatch(
      SmartphoneSaleSliceReducer.actions.verifySmartphoneIMEI({
        imei: smartphoneIMEI,
        contractId,
        productId,
        transactionalCreditEvaluationId,
      })
    )
  }

  const handleKeyPressSmartphoneIMEIVerification = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      verifySmartphoneIMEI()
    }
  }

  return (
    <>
      <Label>Informe o IMEI para confirmar</Label>
      <NumberFormat
        style={formItemStyle}
        id="imei-code-input"
        className={`bp3-input ${
          smartphoneIMEI && !isImeiValid(smartphoneIMEI) ? 'bp3-intent-danger' : 'bp3-intent-none'
        } `}
        placeholder="Número IMEI do aparelho"
        autoComplete="off"
        tabIndex={1}
        mask="_"
        value={smartphoneIMEI}
        onKeyDown={handleKeyPressSmartphoneIMEIVerification}
        onValueChange={(values: NumberFormatValues) => setSmartphoneIMEI(values.value)}
      />

      <PurchaseConfirmationButtons
        onConfirm={verifySmartphoneIMEI}
        onCancel={onCancel}
        loading={isVerifyingSmartphoneIMEILoading}
      />
    </>
  )
}

export default ImeiVerification
