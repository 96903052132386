import styled from 'styled-components'
import React from 'react'
import { Button, ProgressBar, Intent } from '@blueprintjs/core'
import { RETRY_SMS_COUNTDOWN_SECONDS } from '../../../constants'
import { PhoneCommunicationChannel } from '../../../services/bff/originations/dto/PhoneVerificationRecord.dto'
import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../redux/reducers/selectors'
import { startCountdown } from '../../../utils/timer.utils'

interface TokenRetryButtonsProps {
  isOpen: boolean
  isCreatingPurchaseVerification: boolean
  sendNewVerificationCode: (channel: PhoneCommunicationChannel) => void
}

export const TokenRetryButtons: React.FC<TokenRetryButtonsProps> = ({
  isOpen,
  isCreatingPurchaseVerification,
  sendNewVerificationCode,
}) => {
  const [countDownSeconds, setCountDownSeconds] = useState<number>(RETRY_SMS_COUNTDOWN_SECONDS)
  const [newChannel, setNewChannel] = useState<PhoneCommunicationChannel | null>(null)
  const { createdPurchaseVerification, isCreatePurchaseVerificationError } = useTypedSelector(
    (state) => ({
      createdPurchaseVerification: state.purchaseVerification.createdPurchaseVerification,
      isCreatePurchaseVerificationError:
        state.purchaseVerification.isCreatePurchaseVerificationError,
    })
  )

  const handleSendNewVerificationCode = (channel: PhoneCommunicationChannel) => {
    setNewChannel(channel)
    setCountDownSeconds(RETRY_SMS_COUNTDOWN_SECONDS)
    sendNewVerificationCode(channel)
  }

  useEffect(() => {
    if (isOpen) {
      setCountDownSeconds(RETRY_SMS_COUNTDOWN_SECONDS)

      const clearTimer = startCountdown(RETRY_SMS_COUNTDOWN_SECONDS, setCountDownSeconds)
      return () => clearTimer()
    }
  }, [isOpen, createdPurchaseVerification, isCreatePurchaseVerificationError])

  const isCountDownRunning = (): boolean => {
    return Boolean(countDownSeconds > 0)
  }

  return (
    <RetryContainer>
      <ProgressBarTextContainer>
        O código pode ser reenviado novamente após {RETRY_SMS_COUNTDOWN_SECONDS} segundos
      </ProgressBarTextContainer>
      <ProgressBarContainer>
        <ProgressBar
          intent={Intent.SUCCESS}
          value={(RETRY_SMS_COUNTDOWN_SECONDS - countDownSeconds) / RETRY_SMS_COUNTDOWN_SECONDS}
          animate={true}
          stripes={false}
        />
        <ProgressBarText>{countDownSeconds}</ProgressBarText>
      </ProgressBarContainer>

      <StyledButton
        text="SMS"
        intent="primary"
        autoFocus={!isCountDownRunning}
        onClick={() => handleSendNewVerificationCode(PhoneCommunicationChannel.SMS)}
        tabIndex={1}
        type="submit"
        loading={isCreatingPurchaseVerification && newChannel === PhoneCommunicationChannel.SMS}
        disabled={isCountDownRunning() || isCreatingPurchaseVerification}
      />
      <StyledButton
        text="Whatsapp"
        intent="primary"
        autoFocus={!isCountDownRunning}
        onClick={() => handleSendNewVerificationCode(PhoneCommunicationChannel.WHATSAPP)}
        tabIndex={2}
        type="submit"
        loading={
          isCreatingPurchaseVerification && newChannel === PhoneCommunicationChannel.WHATSAPP
        }
        disabled={isCountDownRunning() || isCreatingPurchaseVerification}
      />
      <StyledButton
        text="Chamada telefônica"
        intent="primary"
        autoFocus={!isCountDownRunning}
        onClick={() => handleSendNewVerificationCode(PhoneCommunicationChannel.PHONE_CALL)}
        tabIndex={3}
        type="submit"
        loading={
          isCreatingPurchaseVerification && newChannel === PhoneCommunicationChannel.PHONE_CALL
        }
        disabled={isCountDownRunning() || isCreatingPurchaseVerification}
      />
    </RetryContainer>
  )
}

const StyledButton = styled(Button)`
  margin-bottom: 4px;
  margin-right: 8px;
`

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const RetryContainer = styled.div`
  margin-top: 1.5em;
`

const ProgressBarTextContainer = styled.p``

const ProgressBarText = styled.span`
  margin-top: 0.5em;
`
