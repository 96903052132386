import {
  Button,
  Card,
  Divider,
  Elevation,
  H4,
  Icon,
  Intent,
  Label,
  Spinner,
  Tag,
} from '@blueprintjs/core'
import { ICouponResponse } from '@scudraservicos/coordinator-client/dist/src/services/coupons/interfaces/coupon.res'
import * as Sentry from '@sentry/react'
import { Tooltip } from 'antd'
import moment from 'moment'
import 'moment/locale/pt-br'
import * as React from 'react'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Dispatch } from 'redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import FeatureFlags from '../../common/assets/utils/featureFlags'
import LocalStorageWrapper, {
  LocalStorageItem,
} from '../../common/assets/utils/LocalStorageWrapper'
import SafeListService from '../../common/assets/utils/SafeListService'
import {
  contractErrorResponse,
  creditProfileErrorResponse,
  PurchasesErrorsMessage,
} from '../../common/ErrorStrings'
import { storeConfirmation } from '../../common/StoreConfirmation'
import UmeLinks from '../../common/UmeLinks'
import { validateBr } from '../../common/Validators'
import * as featureFlag from '../../config/config'
import { UME_PHONES } from '../../config/config'
import { MIN_SMS_RETRIALS, RETRY_SMS_COUNTDOWN_SECONDS } from '../../constants'
import PageHeader from '../../molecules/header/components/PageHeader/PageHeader'
import PurchaseContractOptions from '../../molecules/Purchase/PurchaseContractOptions'
import { PurchaseConditions } from '../../molecules/Purchase/PurchaseInstallmentsOptionsCard'
import { simplifyProducts } from '../../organisms/ProductSale/components/ProductsHelper'
import { Product, ProductsSession } from '../../organisms/ProductSale/components/ProductsSession'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import { resolvePurchaseReceiptRoute } from '../../pages/Receipts/Utils'
import { BorrowerStatus, PhoneStatus } from '../../redux/reducers/application/application.types'
import ConfigurationsSliceReducer from '../../redux/reducers/configurations/configurations.reducer'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import { bffParceirosApiService } from '../../services/bff'
import { BorrowerDto } from '../../services/bff/borrowers/dto/Borrower.dto'
import {
  IContract,
  IContractPostBody,
  SourceProduct,
  VerificationMethodTypeEnum,
} from '../../services/bff/coordinator/dto/Contracts.dto'
import { StoreDto } from '../../services/bff/retailers/dto/Store.dto'
import { TransactionalCreditEvaluation } from '../../services/bff/transactional-credit/dtos/transactional-credit-evaluation.dto'
import { TransactionalCreditEvaluationResult } from '../../services/bff/transactional-credit/enums/transactional-credit-evaluation-results.enum'
import { TransactionalCreditEvaluationStatus } from '../../services/bff/transactional-credit/enums/transactional-credit-evaluation-status.enum'
import { CaptureMethodService } from '../../services/captureMethodService/CaptureMethodService'
import { AutoScrollToView } from '../common/AutoScrollToView'
import BorrowerProfile from '../common/BorrowerProfile'
import { formatBRL } from '../common/formatBRL'
import { formatCellphoneWithoutNinthDigit } from '../common/formatCellPhone'
import {
  buttonStyle,
  formActionsStyle,
  formContainerStyle,
  formItemsContainerStyle,
  formItemStyle,
  h5Style,
  tableStyleHeaderCell,
  tableStyleHeaderDiscountCell,
  tableStyleHeaderWithoutDiscountCell,
  tableStyleRowCell,
  tableStyleRowDiscountCell,
  tableStyleRowWithoutDiscountCell,
  textInputGroupStyle,
  textInputGroupWithLabelStyle,
} from '../styles'
import { orangeSpan, purchseValueGroup, redSpan, spanContainer } from './styles'
import TransactionalCreditEvaluationCard from './TransactionalCreditEvaluationCard'
import { WaitingPurchaseEval } from './WaitingPurchaseEval'
import { PurchaseConfirmationPopup } from '../../molecules/Purchase/PurchaseConfirmationPopup'

enum RegistrationCheckStatus {
  WaitingCPFInput,
  FetchingBorrowerInfo,
  IsApproved,
  NotApproved,
}

enum LimitCheckStatus {
  WaitingPurchaseInput,
  CheckingLimitAgainstPurchase,
  IsApproved,
  NotApproved,
}

enum PurchaseEvalStatus {
  WaitingPurchaseEvalRequest,
  WaitingPurchaseEval,
  IsApproved,
  NotApproved,
}

export enum SmartphoneIMEIVerificationStep {
  Pending,
  SendingIMEI,
  Verified,
}

export interface IPurchaseState {
  registrationCheckStatus: RegistrationCheckStatus
  limitCheckStatus: LimitCheckStatus
  borrower?: BorrowerDto
  borrowerId: string
  operatorId: string
  borrowerError: string
  cpf: string
  formattedCPF: string
  cpfIntent: Intent
  isVerificationDialogOpen: boolean
  formattedCellphone: string
  verificationId: string
  portraitBase64: string
  approvedLimit: number
  limitIsBlockedBy?: string
  showPaymentLink: boolean
  availableLimit: number
  borrowerName: string
  isCountDownPopoverOpen: boolean
  purchaseValue: string
  downPayment?: number
  purchaseValueMinusDownPayment?: number
  principal: number
  productPrice: number
  productId?: string
  isPurchaseConfirmed: boolean
  purchaseConditions: PurchaseConditions[]
  firstInstallmentDueDate?: string
  selectedPurchaseCondition: number
  purchaseEvalStatus: PurchaseEvalStatus
  contractId: string
  contract?: IContract
  contractError: string
  sourceProduct?: string
  verificationMethod?: VerificationMethodTypeEnum

  phoneVerificationError: string

  managerPassword: string
  managerSelected?: string
  verificationsAttempts: number
  showManagerAuthLink: boolean
  isManagerAuthDialogOpen: boolean
  coupon?: ICouponResponse

  authorizationStrategy: 'BORROWER_SMS' | 'ANALYST_VERIFICATION'

  timer: number

  store?: StoreDto
  transactionCreditEvaluation: TransactionalCreditEvaluation | undefined
  transactionCreditEvaluationActivated: boolean
}

const initialState: IPurchaseState = {
  registrationCheckStatus: RegistrationCheckStatus.WaitingCPFInput,
  limitCheckStatus: LimitCheckStatus.WaitingPurchaseInput,
  borrower: undefined,
  borrowerId: '',
  operatorId: '',
  borrowerError: '',
  cpf: '',
  formattedCPF: '',
  cpfIntent: Intent.NONE,
  phoneVerificationError: '',
  isVerificationDialogOpen: false,
  isPurchaseConfirmed: false,
  isCountDownPopoverOpen: false,
  formattedCellphone: '',
  verificationId: '',
  portraitBase64: '',
  showPaymentLink: false,
  approvedLimit: 0,
  availableLimit: 0,
  limitIsBlockedBy: '',
  borrowerName: '',
  purchaseValue: '',
  downPayment: undefined,
  purchaseValueMinusDownPayment: undefined,
  principal: 0,
  productPrice: 0,
  productId: undefined,
  contractError: '',
  purchaseConditions: [],
  selectedPurchaseCondition: -1,
  purchaseEvalStatus: PurchaseEvalStatus.WaitingPurchaseEvalRequest,
  contractId: '',
  contract: undefined,
  managerPassword: '',
  managerSelected: undefined,
  verificationsAttempts: 0,
  showManagerAuthLink: false,
  isManagerAuthDialogOpen: false,
  authorizationStrategy: 'BORROWER_SMS',
  timer: RETRY_SMS_COUNTDOWN_SECONDS,
  firstInstallmentDueDate: undefined,
  transactionCreditEvaluation: undefined,
  transactionCreditEvaluationActivated: true,
  verificationMethod: undefined,
}

export interface RouterProps {
  title?: string
  history?: any
  location?: any
  dispatch: Dispatch
}
class Purchases extends React.Component<RouterProps, IPurchaseState> {
  private tableRef: React.RefObject<HTMLTableElement>
  private confirmPurchaseButtonRef: React.RefObject<HTMLButtonElement>

  state = { ...initialState } // use spread operator to avoid mutation

  public handleReset = () => {
    this.setState(initialState)
  }

  constructor(props: RouterProps) {
    super(props)
    this.tableRef = React.createRef()
    this.confirmPurchaseButtonRef = React.createRef()

    // TODO(lucas.citolin): fetch from a global reducer so the code doesn't need to fetch multiple times as it is doing
    this.setStoreWithSettings()
  }

  async setStoreWithSettings() {
    let store = bffParceirosApiService.coordinator.getStore()
    if (!store) {
      return
    }
    const storeWithSettings = await bffParceirosApiService.retailers.fetchStoreById(store.id)
    this.setState({ ...this.state, store: storeWithSettings })
  }

  async componentDidMount() {
    const cpf = this.props.history.location.state && this.props.history.location.state.cpf
    if (cpf) {
      this.setState({ cpf }, async () => {
        await this.fetchBorrowerInfo()
      })
    }
  }

  componentDidUpdate() {
    if (
      this.tableRef &&
      this.tableRef.current &&
      this.state.purchaseConditions.length &&
      !this.state.isVerificationDialogOpen
    ) {
      const focusedElementIdx = this.state.purchaseConditions.map((x) => x.isFocused).indexOf(true)
      const focusedElement = this.tableRef.current.tBodies[0].children[
        focusedElementIdx
      ] as HTMLElement
      focusedElement.focus()
    }

    if (
      this.confirmPurchaseButtonRef &&
      this.confirmPurchaseButtonRef.current &&
      this.state.isVerificationDialogOpen
    ) {
      const confirmPurchaseButton = this.confirmPurchaseButtonRef.current
      confirmPurchaseButton.focus()
    }

    if (
      this.confirmPurchaseButtonRef &&
      this.confirmPurchaseButtonRef.current &&
      this.state.isPurchaseConfirmed
    ) {
      const confirmPurchaseButton = this.confirmPurchaseButtonRef.current
      confirmPurchaseButton.blur()
    }
  }

  public onKeyPressTableRow = (index: number, event: React.KeyboardEvent) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ contractError: '', borrowerError: '', phoneVerificationError: '' })

    if (event.key === 'ArrowDown' && index < this.state.purchaseConditions.length) {
      if (index === this.state.purchaseConditions.length - 1) {
        this.setState({
          purchaseConditions: this.state.purchaseConditions.map((x, idx) => ({
            ...x,
            ...{ isFocused: idx === 0 },
          })),
        })
      } else {
        this.setState({
          purchaseConditions: this.state.purchaseConditions.map((x, idx) => ({
            ...x,
            ...{ isFocused: idx === index + 1 },
          })),
        })
      }
    }

    if (event.key === 'ArrowUp' && index > 0) {
      this.setState({
        purchaseConditions: this.state.purchaseConditions.map((x, idx) => ({
          ...x,
          ...{ isFocused: idx === index - 1 },
        })),
      })
    }

    if (event.key === ' ') {
      this.togglePurchaseConditionSelected(index)
    }

    if (
      event.key === 'Enter' &&
      this.state.purchaseConditions.filter((x) => x.isSelected).length > 0
    ) {
      this.openVerificationDialog()
    }
  }

  public togglePurchaseConditionSelected = (purchaseConditionIdx: number) => {
    this.setState({
      purchaseConditions: this.state.purchaseConditions.map((x, idx) => ({
        ...x,
        ...(idx === purchaseConditionIdx
          ? { isSelected: !x.isSelected, isFocused: true }
          : { isSelected: false, isFocused: false }),
      })),
      selectedPurchaseCondition: this.state.purchaseConditions[purchaseConditionIdx].isSelected
        ? -1
        : purchaseConditionIdx,
      isPurchaseConfirmed: false,
    })
  }

  public onManagerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const managerId = e.currentTarget.value
    this.setState({ managerSelected: managerId })
  }

  public onManagerPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ managerPassword: e.currentTarget.value })
  }

  public openManagerDialog = () => {
    this.setState({ isManagerAuthDialogOpen: true })
  }

  public closeManagerDialog = () => {
    this.setState({ isManagerAuthDialogOpen: false })
  }

  public fetchBorrowerInfo = async () => {
    analyticsService.sendEvent(AnalyticsEvents.purchase_borrower_document)
    this.setState({
      registrationCheckStatus: RegistrationCheckStatus.FetchingBorrowerInfo,
    })

    let borrower
    let limit = {
      borrowerId: '',
      retailerId: '',
      totalLimit: 0,
      limitIsBlockedBy: '',
      attributedOn: '',
      availableLimit: 0,
    }
    let portrait
    let operatorId = ''

    try {
      borrower = await bffParceirosApiService.borrowers.findBorrowerByCpf(this.state.cpf)
    } catch (err) {
      let borrowerErrorStatus
      err.response
        ? (borrowerErrorStatus = creditProfileErrorResponse[err.response.data.error])
        : (borrowerErrorStatus = creditProfileErrorResponse.UNKNOWN_ERROR)

      this.setState({
        borrowerError: borrowerErrorStatus,
        registrationCheckStatus: RegistrationCheckStatus.WaitingCPFInput,
      })
      toaster.showErrorToast(this.state.borrowerError)
    }

    if (borrower) {
      try {
        let store = bffParceirosApiService.coordinator.getStore()

        const user = await bffParceirosApiService.auth.decodeJwt()
        const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)
        operatorId = operator.id

        if (!store) {
          const storeId = operator.storeId
          store = await bffParceirosApiService.retailers.fetchStoreById(storeId)
          storeConfirmation(store)
        }

        if (FeatureFlags.allowTransactionalCreditEvaluation()) {
          limit = await bffParceirosApiService.coordinator.fetchBorrowerLimitForStore(
            borrower.id,
            store.id
          )
          this.fetchEvaluation(this.state.cpf, store.id, store.retailerId)
        }

        if (featureFlag.cashierShowLimit) {
          limit = await bffParceirosApiService.coordinator.fetchBorrowerLimitForStore(
            borrower.id,
            store.id
          )
        }

        portrait = await bffParceirosApiService.borrowers.fetchBorrowerPortrait(borrower.id)
      } catch (error) {
        let borrowerErrorStatus
        error.response
          ? (borrowerErrorStatus = creditProfileErrorResponse[error.response.data.error])
          : (borrowerErrorStatus = creditProfileErrorResponse.UNKNOWN_ERROR)

        this.setState({
          borrowerError: borrowerErrorStatus,
          registrationCheckStatus: RegistrationCheckStatus.WaitingCPFInput,
        })
        toaster.showErrorToast(this.state.borrowerError)
      }
    }

    if (borrower && borrower.name && portrait) {
      let cellphone: string = ''
      let limitCheckStatus = 0
      let formattedCellphone = ''
      if (borrower.phoneNumber) {
        cellphone = borrower.phoneNumber
        formattedCellphone = `(${cellphone.slice(3, 5)})${cellphone
          .slice(5, 10)
          .replace(/[0-9]/g, '*')}-${cellphone.slice(10, 14)}`
      }

      limitCheckStatus = LimitCheckStatus.WaitingPurchaseInput

      this.setState({
        registrationCheckStatus: RegistrationCheckStatus.IsApproved,
        limitCheckStatus,
        operatorId,
        showPaymentLink: Boolean(limit.limitIsBlockedBy),
        portraitBase64: portrait.imagebase64,
        approvedLimit: limit.totalLimit,
        availableLimit: limit.availableLimit,
        borrowerName: borrower.name,
        cpf: '',
        formattedCPF: '',
        formattedCellphone,
        borrower: borrower,
        borrowerId: borrower.id,
      })
    }
  }

  private async fetchEvaluation(cpf: string, storeId: string, retailerId: string) {
    const evaluation = await bffParceirosApiService.transactionalCredit.getAvailableEvaluation(
      cpf,
      storeId,
      retailerId,
      TransactionalCreditEvaluationStatus.ACCEPTED,
      [
        TransactionalCreditEvaluationResult.APPROVED,
        TransactionalCreditEvaluationResult.PARTIALLY_APPROVED,
      ]
    )
    this.setState({ transactionCreditEvaluation: evaluation })
  }

  public fetchNextPurchaseCoupon = async (
    borrowerId: string
  ): Promise<ICouponResponse | undefined> => {
    let store = bffParceirosApiService.coordinator.getStore()

    if (!store || !borrowerId) {
      return undefined
    }

    const coupon = await bffParceirosApiService.coupons.fetchActiveNextPurchaseCoupons({
      borrowerId,
      retailerId: store.retailerId,
      storeId: store.id,
    })

    this.setState({
      coupon: coupon,
    })

    return coupon
  }

  public checkLimitAgainstPurchase = async () => {
    let store = bffParceirosApiService.coordinator.getStore()

    if (!store) {
      const user = await bffParceirosApiService.auth.decodeJwt()

      const operator = await bffParceirosApiService.coordinator.fetchOperatorById(user.id)
      const storeId = operator.storeId
      store = await bffParceirosApiService.retailers.fetchStoreById(storeId)
      storeConfirmation(store)
    }

    this.setState({
      limitCheckStatus: LimitCheckStatus.CheckingLimitAgainstPurchase,
      contractError: '',
    })

    const purchaseValue = parseFloat(this.state.purchaseValue)

    analyticsService.sendEvent(AnalyticsEvents.purchase_proposal, { value: purchaseValue })

    // TODO: Validate purchase value
    if (isNaN(purchaseValue)) {
      toaster.showErrorToast(contractErrorResponse.INVALID_PURCHASE_VALUE)
      return
    }

    let coupon

    try {
      coupon = await this.fetchNextPurchaseCoupon(this.state.borrowerId)
    } catch (error) {
      // if not 404. 404 indicates no active coupons
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message !== 'COUPON_NOT_FOUND'
      ) {
        if (!error.response || (error.response && error.response.status >= 500)) {
          const context = {
            req: error.config ? error.config : 'No request data on exception',
            res: error.response?.data
              ? { data: error.response.data, status: error.response.status }
              : 'No response data on exception',
          }

          Sentry.captureException(error, { extra: context })
        }
      }
    }

    let contract: IContract | undefined

    try {
      const localCashierId = LocalStorageWrapper.getItem(LocalStorageItem.LOCAL_CASHIER_ID)

      const body: IContractPostBody = {
        borrowerId: this.state.borrowerId,
        principal: purchaseValue,
        storeId: store.id,
        localCashierId,
        couponId: coupon ? coupon.id : undefined,
      }

      if (this.state.transactionCreditEvaluationActivated)
        body.transactionalCreditEvaluationId = this.state.transactionCreditEvaluation?.id

      contract = await bffParceirosApiService.coordinator.getProposals(body)
    } catch (err) {
      console.error('error fetching proposals')
      console.error(err)
      let contractErrorStatus
      err.response
        ? (contractErrorStatus = contractErrorResponse[err.response.data.error])
        : (contractErrorStatus = contractErrorResponse.UNKNOWN_ERROR)

      if (
        err.response &&
        (err.response.data.error === 'OVERDUE_INSTALLMENTS' ||
          err.response.data.error === 'OPEN_RENEGOTIATIONS')
      ) {
        this.setState({ showPaymentLink: true })
      }
      this.setState({
        contractError: contractErrorStatus,
        limitCheckStatus: LimitCheckStatus.WaitingPurchaseInput,
      })
      toaster.showErrorToast(this.state.contractError)
      if (this.state.contractError == PurchasesErrorsMessage.DEACTIVATED_STORE) {
        this.props.dispatch(ConfigurationsSliceReducer.actions.handleDeactivatedStore())
      }
    }

    if (contract) {
      // TODO: temporary solution to minimize fraud, remove ASAP
      let authorizationStrategy: 'BORROWER_SMS' | 'ANALYST_VERIFICATION' = 'BORROWER_SMS'

      if (
        (contract as any).authorizationStrategy &&
        (contract as any).authorizationStrategy === 'ANALYST_VERIFICATION'
      ) {
        authorizationStrategy = 'ANALYST_VERIFICATION'
      }

      this.setState({
        contractId: contract.id,
        purchaseConditions: contract.proposals
          .sort((a, b) => a.numberOfInstallments - b.numberOfInstallments)
          .map((p, i) => ({
            proposalId: p.id,
            cet: p.cet,
            interestRate: p.interestRate,
            installmentValue: p.installmentValue,
            installmentValueWithoutInterest: p.installmentValueWithoutInterest,
            installments: p.numberOfInstallments,
            isSelected: false,
            isFocused: i === 0,
            partnerProposalId: p.partnerProposalId,
            iof: p.iof,
            dueDateRule: p.dueDateRule,
            customerFeeRate: contract?.customerFeeRate,
            customerFeeValue: contract?.customerFeeValue,
            firstInstallmentDueDate: p.firstInstallmentDueDate,
          })),
        selectedPurchaseCondition: -1,
        limitCheckStatus: LimitCheckStatus.IsApproved,
        authorizationStrategy: authorizationStrategy,
        firstInstallmentDueDate: contract.firstInstallmentDueDate,
        sourceProduct: contract.sourceProduct,
        principal: contract.principal,
        verificationMethod: contract.verificationMethod,
      })
    }
  }

  public onCPFChange = (values: NumberFormatValues) => {
    if (values.value.length > 0) {
      this.setState({
        ...initialState,
        coupon: undefined,
        cpf: values.value,
        contractError: '',
        borrowerError: '',
        phoneVerificationError: '',
        registrationCheckStatus: RegistrationCheckStatus.WaitingCPFInput,
        limitCheckStatus: LimitCheckStatus.WaitingPurchaseInput,
      })
    }
  }

  public onPurchaseValueChange = (values: NumberFormatValues) => {
    this.setState({
      purchaseValue: values.value,
      limitCheckStatus: LimitCheckStatus.WaitingPurchaseInput,
      contractError: '',
      borrowerError: '',
      phoneVerificationError: '',
    })
  }

  public handleKeyPressCpf = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.fetchBorrowerInfo()
    }
  }

  public handleKeyPressCancel = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.cancelPurchase()
    }
  }

  public handleKeyPressPurchase = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.checkLimitAgainstPurchase()
    }
  }

  public handleKeyOpenDialog = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.openVerificationDialog()
    }
  }

  public handleKeyPressAcceptPurchaseCondition = async (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      await this.startVerificationProcess()
      analyticsService.sendEvent(AnalyticsEvents.purchase_selected_installments)
    }
  }

  public handleKeyPressLimitVerification = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && this.state.limitCheckStatus !== LimitCheckStatus.NotApproved) {
      this.checkLimitAgainstPurchase()
    }
  }

  public handleKeyPressFinishPurchase = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.finishPurchase()
    }
  }

  public handleKeyPressCancelPurchase = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.cancelPurchase()
    }
  }

  public openVerificationDialog = () => {
    this.setState({
      isVerificationDialogOpen: true,
      isPurchaseConfirmed: false,
    })
  }

  public openCountDownPopover = () => {
    this.setState({
      isCountDownPopoverOpen: true,
      phoneVerificationError: '',
    })
  }

  public closeCountDownPopover = () => {
    this.setState({
      isCountDownPopoverOpen: false,
    })
  }

  public startVerificationProcess = async () => {
    this.setState({
      isPurchaseConfirmed: true,
    })
  }

  createSmartphoneSaleContract = (product: Product) => {
    this.setState(
      {
        purchaseValue: product.priceMinusDownPayment
          ? String(product.priceMinusDownPayment)
          : String(product.price),
        downPayment: product.downPayment,
        purchaseValueMinusDownPayment: product.priceMinusDownPayment,
        principal: this.state.principal,
        productPrice: product.price,
        productId: product.id,
        limitCheckStatus: LimitCheckStatus.WaitingPurchaseInput,
        contractError: '',
        borrowerError: '',
        phoneVerificationError: '',
      },
      () => {
        this.checkLimitAgainstPurchase()
      }
    )
  }

  public cancelPurchase = () => {
    this.setState({
      phoneVerificationError: '',
      contractError: '',
      borrowerError: '',
      isVerificationDialogOpen: false,
      isPurchaseConfirmed: false,
      verificationId: '',
      selectedPurchaseCondition: -1,
      purchaseConditions: this.state.purchaseConditions.map((x, idx) => ({
        ...x,
        isSelected: false,
        isFocused: idx === 0,
      })),
    })
  }

  public finishPurchase = async () => {
    analyticsService.sendEventWithDuration(
      AnalyticsEvents.purchase_success,
      AnalyticsEvents.purchase_borrower_document
    )
    const selectedProposalId = this.state.purchaseConditions[this.state.selectedPurchaseCondition]
      .proposalId

    this.setState({
      purchaseEvalStatus: PurchaseEvalStatus.WaitingPurchaseEval,
    })

    let contract

    try {
      contract = await bffParceirosApiService.coordinator.acceptProposal(selectedProposalId)
    } catch (err) {
      const contractErrorStatus =
        contractErrorResponse[err.response?.data?.message?.error] ??
        contractErrorResponse['UNKNOWN_ERROR']

      this.setState({
        contractError: contractErrorStatus,
        purchaseEvalStatus: PurchaseEvalStatus.NotApproved,
      })
      toaster.showErrorToast(this.state.contractError)
      console.error(err)
      if (this.state.contractError == PurchasesErrorsMessage.DEACTIVATED_STORE) {
        this.props.dispatch(ConfigurationsSliceReducer.actions.handleDeactivatedStore())
      }
    }

    if (contract) {
      this.setState({
        purchaseEvalStatus: PurchaseEvalStatus.IsApproved,
        contractId: contract.id,
        contract: contract,
      })
    }
  }

  public cpfIsValid(cpf: string) {
    return validateBr.cpf(cpf)
  }

  redirectToPayment = () => {
    return this.props.history.push({
      pathname: '/caixa/pagamentos',
      state: {
        cpf: this.state.cpf,
      },
    })
  }

  isPurchaseAllowed(borrower?: BorrowerDto): boolean {
    return (
      !!borrower &&
      (!borrower.borrowerStatus || borrower.borrowerStatus === BorrowerStatus.Approved) &&
      (!borrower.phoneStatus || borrower.phoneStatus === PhoneStatus.VerifiedPhone) &&
      !borrower.borrowerSettings?.disablePurchase
    )
  }

  isSuperPurchaseSourceProduct(
    transactionCreditEvaluation: TransactionalCreditEvaluation
  ): boolean {
    return (
      !transactionCreditEvaluation.sourceProduct ||
      transactionCreditEvaluation.sourceProduct == SourceProduct.SUPER_PURCHASE
    )
  }

  resolveAlertMessages(borrowerError: string, borrower?: BorrowerDto): JSX.Element {
    if (!borrower) {
      if (borrowerError !== '') {
        return (
          <div style={spanContainer}>
            <span style={orangeSpan}>
              O CPF informado não foi cadastrado.
              <br /> Procure um vendedor e realize seu cadastro
            </span>
          </div>
        )
      }
      return <></>
    }
    if (borrower.phoneStatus !== PhoneStatus.VerifiedPhone) {
      return (
        <div style={spanContainer}>
          <span style={redSpan}>
            Telefone não verificado!
            <br />
            Procure um vendedor para concluir o cadastro
          </span>
        </div>
      )
    }
    if (borrower.borrowerSettings?.disablePurchase) {
      return (
        <div style={spanContainer}>
          <span style={{ ...redSpan, fontWeight: 'bold' }}>
            Este cliente foi desabilitado para realizar compras temporariamente.
            <br />
            Será necessário atualização cadastral.
            <br />
            {`Solicite ao cliente que entre em contato com nossa central de atendimento pelo WhatsApp ${formatCellphoneWithoutNinthDigit(
              UME_PHONES.customerService.whatsapp
            )}`}
          </span>
        </div>
      )
    }
    switch (borrower.borrowerStatus) {
      case BorrowerStatus.Denied:
        return (
          <div style={spanContainer}>
            <span style={redSpan}>Esse cliente não foi aprovado :(</span>
          </div>
        )
      case BorrowerStatus.Incomplete:
        return (
          <div style={spanContainer}>
            <span style={redSpan}>
              O cliente não finalizou o cadastro.
              <br />
              Procure um vendedor para concluir o cadastro
            </span>
          </div>
        )
      case BorrowerStatus.ManualAnalysis:
        return (
          <div style={spanContainer}>
            <span style={redSpan}>O cliente está sendo avaliado manualmente.</span>
          </div>
        )
      case BorrowerStatus.UnderAnalysis:
        return (
          <div style={spanContainer}>
            <span style={redSpan}>O cliente está sendo avaliado, aguarde um momento.</span>
          </div>
        )
      case BorrowerStatus.PreApproved:
        return (
          <div style={spanContainer}>
            <span style={redSpan}>O cliente está sendo avaliado, aguarde um momento.</span>
          </div>
        )
      default:
        return <></>
    }
  }

  handleChangeCaptureMethodClick = () => {
    this.props.history.push('/caixa/compras/qrcode')
  }

  public render() {
    const {
      cpfIntent,
      cpf,
      registrationCheckStatus,
      portraitBase64,
      borrower,
      borrowerId,
      operatorId,
      coupon,
      borrowerName,
      approvedLimit,
      availableLimit,
      limitCheckStatus,
      limitIsBlockedBy,
      purchaseValue,
      isPurchaseConfirmed,
      purchaseConditions,
      selectedPurchaseCondition,
      purchaseEvalStatus,
      contractId,
      sourceProduct,

      isVerificationDialogOpen,

      formattedCellphone,
      contractError,

      showPaymentLink,
      authorizationStrategy,
      borrowerError,
      firstInstallmentDueDate,
    } = this.state

    if (purchaseEvalStatus === PurchaseEvalStatus.WaitingPurchaseEval) {
      return (
        <div style={formContainerStyle}>
          {' '}
          <WaitingPurchaseEval />{' '}
        </div>
      )
    }

    if (purchaseEvalStatus === PurchaseEvalStatus.IsApproved) {
      const contractReceiptUri = resolvePurchaseReceiptRoute(contractId)
      window.open(contractReceiptUri, '_blank')

      return (
        <Redirect
          push
          to={{
            pathname: PagesPathEnum.PURCHASE_CONFIRMATION.toString().replace(':id', contractId),
          }}
        />
      )
    }

    const retailerId = bffParceirosApiService.coordinator.getStore()?.retailerId
    const shouldRenderNewPurchaseContractOptions = SafeListService.shouldRenderNewPurchaseContractOptionsForRetailersSafeList(
      retailerId
    )
    let couponDiscount = 0

    // this businesss logic should probably be done in the backend
    if (
      coupon &&
      purchaseValue &&
      !isNaN(Number(purchaseValue)) &&
      Number(purchaseValue) >= coupon.minimumPurchaseValue &&
      coupon.discountType === '%' &&
      coupon.percentValue
    ) {
      if (coupon.maximumDiscountValue) {
        couponDiscount = Math.min(
          coupon.percentValue * Number(purchaseValue),
          coupon.maximumDiscountValue
        )
      } else {
        couponDiscount = coupon.percentValue * Number(purchaseValue)
      }
    }

    if (
      coupon &&
      purchaseValue &&
      !isNaN(Number(purchaseValue)) &&
      Number(purchaseValue) >= coupon.minimumPurchaseValue &&
      coupon.discountType === 'R$' &&
      coupon.monetaryValue
    ) {
      couponDiscount = coupon.monetaryValue
    }

    return (
      <div>
        <PageHeader
          identificationUserContainerStyle={{
            marginBottom: '2%',
            marginTop: '2%',
            marginLeft: '2%',
          }}
        />
        <Header>
          <h1 style={h5Style} className="bp3-heading">
            Compra
          </h1>
        </Header>
        <div style={formContainerStyle}>
          <Card style={formItemsContainerStyle} interactive={false} elevation={Elevation.TWO}>
            <div style={textInputGroupStyle} onKeyDown={this.handleKeyPressCpf}>
              <div style={textInputGroupWithLabelStyle}>
                <Label
                  style={{ ...formItemStyle, marginTop: '4px', marginBottom: '4px' }}
                  className="bp3-label"
                >
                  CPF
                </Label>
                <NumberFormat
                  style={formItemStyle}
                  format="###.###.###-##"
                  mask="_"
                  id="text-input"
                  placeholder="000.000.000-00"
                  tabIndex={1}
                  intent={cpfIntent}
                  value={cpf}
                  autoComplete="off"
                  className={`bp3-input-group bp3-input ${
                    cpf && !this.cpfIsValid(cpf) ? 'bp3-intent-danger' : 'bp3-intent-none'
                  } `}
                  onValueChange={this.onCPFChange}
                  autoFocus
                />
              </div>
              <div>
                <Button
                  id="button_search_document"
                  style={{ ...formItemStyle, marginLeft: '16px', ...buttonStyle }}
                  rightIcon={
                    registrationCheckStatus === RegistrationCheckStatus.WaitingCPFInput && 'search'
                  }
                  intent="primary"
                  tabIndex={1}
                  text={
                    registrationCheckStatus === RegistrationCheckStatus.WaitingCPFInput &&
                    'Pesquisar'
                  }
                  disabled={registrationCheckStatus !== RegistrationCheckStatus.WaitingCPFInput}
                  onClick={this.fetchBorrowerInfo}
                  loading={registrationCheckStatus === RegistrationCheckStatus.FetchingBorrowerInfo}
                >
                  {(registrationCheckStatus === RegistrationCheckStatus.IsApproved ||
                    registrationCheckStatus === RegistrationCheckStatus.NotApproved) && (
                    <Icon icon="tick" />
                  )}
                </Button>
              </div>
            </div>
            <ChangeCaptureMethodContainer>
              {CaptureMethodService.isAllowedToUseQRCodeCaptureMethod(
                this.state.store?.settings?.captureMethod
              ) ? (
                <Tooltip
                  placement="top"
                  title={
                    <span>
                      Realize sua venda de maneira mais rápida! Gere um QR Code e solicite ao
                      cliente que faça a leitura através do App da Ume.{' '}
                      <QRCodeCaptureMethodTooltipLink
                        href={UmeLinks.QR_CODE_CAPTURE_METHOD_EXPLANATION_URL}
                        target="_blank"
                      >
                        Saiba mais aqui.
                      </QRCodeCaptureMethodTooltipLink>
                    </span>
                  }
                  color={'black'}
                >
                  <ChangeCaptureMethod onClick={this.handleChangeCaptureMethodClick}>
                    Clique aqui para realizar a venda via QR Code Ume <Icon icon="help" />
                  </ChangeCaptureMethod>
                </Tooltip>
              ) : (
                undefined
              )}
            </ChangeCaptureMethodContainer>
            {registrationCheckStatus === RegistrationCheckStatus.IsApproved &&
              this.isPurchaseAllowed(borrower) && (
                <>
                  <AutoScrollToView />
                  <BorrowerProfile
                    borrowerId={borrowerId}
                    operatorId={operatorId}
                    phoneNumber={formattedCellphone}
                    name={borrowerName}
                    portraitBase64={portraitBase64}
                    approvedLimit={approvedLimit}
                    availableLimit={availableLimit}
                    enableHighRecurrence={Boolean(borrower?.enableHighRecurrence)}
                  />
                  {this.state.transactionCreditEvaluation &&
                  this.isSuperPurchaseSourceProduct(this.state.transactionCreditEvaluation) ? (
                    <>
                      <Divider />
                      <div style={{ paddingTop: '24px', paddingBottom: '24px' }}>
                        <TransactionalCreditEvaluationCard
                          evaluation={this.state.transactionCreditEvaluation}
                          availableLimit={availableLimit}
                          store={this.state.store}
                          onActivationChanged={() => {
                            this.setState({
                              transactionCreditEvaluationActivated: !this.state
                                .transactionCreditEvaluationActivated,
                            })
                          }}
                          checked={this.state.transactionCreditEvaluationActivated}
                        />
                      </div>
                    </>
                  ) : (
                    undefined
                  )}

                  <Divider />
                  <div style={{ ...purchseValueGroup, flexWrap: 'wrap' }}>
                    <div
                      style={{
                        ...textInputGroupStyle,
                      }}
                    >
                      {this.state.transactionCreditEvaluation &&
                      this.state.transactionCreditEvaluation?.sourceProduct ===
                        SourceProduct.SMARTPHONE_SALE ? (
                        <SmartphoneContainer>
                          <ProductsSession
                            products={simplifyProducts(
                              this.state.transactionCreditEvaluation?.products
                            )}
                            canSelectItems={true}
                            onProductSelect={this.createSmartphoneSaleContract}
                            layout={'responsive'}
                          />
                        </SmartphoneContainer>
                      ) : (
                        <>
                          <div
                            style={{ ...textInputGroupWithLabelStyle, alignItems: 'flex-start' }}
                          >
                            <Label
                              style={{ ...formItemStyle, marginTop: '4px', marginBottom: '4px' }}
                              className="bp3-label"
                            >
                              Valor da compra
                            </Label>

                            <NumberFormat
                              style={formItemStyle}
                              id="text-input"
                              placeholder="Digite o valor"
                              autoComplete="off"
                              tabIndex={1}
                              value={purchaseValue}
                              onValueChange={this.onPurchaseValueChange}
                              onKeyDown={this.handleKeyPressLimitVerification}
                              isNumericString={true}
                              decimalScale={2}
                              type={'tel'}
                              prefix={'R$ '}
                              allowNegative={false}
                              className={`bp3-input-group bp3-input ${
                                purchaseValue && Number(purchaseValue) < 1.0
                                  ? 'bp3-intent-danger'
                                  : 'bp3-intent-none'
                              } `}
                              thousandSeparator="."
                              decimalSeparator=","
                              disabled={limitCheckStatus === LimitCheckStatus.NotApproved}
                            />
                          </div>
                          <div>
                            <Button
                              style={{ marginLeft: '16px', width: '160px' }}
                              intent="primary"
                              tabIndex={1}
                              rightIcon={
                                limitCheckStatus === LimitCheckStatus.WaitingPurchaseInput ||
                                contractError
                                  ? 'properties'
                                  : false
                              }
                              text={
                                (limitCheckStatus === LimitCheckStatus.WaitingPurchaseInput ||
                                  contractError) &&
                                'Checar condições'
                              }
                              disabled={
                                limitCheckStatus !== LimitCheckStatus.WaitingPurchaseInput &&
                                !contractError
                              }
                              onClick={this.checkLimitAgainstPurchase}
                              loading={
                                limitCheckStatus ===
                                  LimitCheckStatus.CheckingLimitAgainstPurchase && !contractError
                              }
                            >
                              {(limitCheckStatus === LimitCheckStatus.IsApproved ||
                                limitCheckStatus === LimitCheckStatus.NotApproved) &&
                                !contractError && <Icon icon="tick" />}
                            </Button>
                          </div>
                        </>
                      )}

                      <Divider style={{ marginBottom: '10px' }} />
                    </div>

                    {limitCheckStatus === LimitCheckStatus.IsApproved &&
                    !shouldRenderNewPurchaseContractOptions ? (
                      <div style={{ marginRight: '32px' }}>
                        {couponDiscount > 0 && (
                          <Tag style={{ marginRight: '1em', color: '#219653' }} minimal large>
                            <p>Cupom</p>
                            <H4>{formatBRL(couponDiscount)}</H4>
                          </Tag>
                        )}
                        {firstInstallmentDueDate && (
                          <Tag minimal large>
                            <p>Vencimento 1ª parcela</p>
                            <H4>{moment(firstInstallmentDueDate).format('L')}</H4>
                          </Tag>
                        )}
                      </div>
                    ) : null}
                  </div>
                  {limitCheckStatus === LimitCheckStatus.NotApproved && (
                    <div>
                      <h3>{creditProfileErrorResponse[limitIsBlockedBy || '']}</h3>
                    </div>
                  )}
                  {showPaymentLink && (
                    <div style={{ ...textInputGroupStyle, marginTop: 0 }}>
                      <a
                        href="#/"
                        tabIndex={1}
                        onKeyDown={this.redirectToPayment}
                        onClick={this.redirectToPayment}
                      >
                        {' '}
                        {'Efetuar pagamentos em aberto'}{' '}
                      </a>
                    </div>
                  )}
                  <Divider style={{ marginBottom: '30px' }} />
                </>
              )}

            {this.resolveAlertMessages(borrowerError, borrower)}
            {this.state.limitCheckStatus === LimitCheckStatus.CheckingLimitAgainstPurchase &&
            this.state.transactionCreditEvaluation?.sourceProduct ===
              SourceProduct.SMARTPHONE_SALE ? (
              <Spinner size={30} intent="success" />
            ) : (
              undefined
            )}
            {limitCheckStatus === LimitCheckStatus.IsApproved &&
              (shouldRenderNewPurchaseContractOptions ? (
                <>
                  <AutoScrollToView />
                  <PurchaseContractOptions
                    purchaseValue={purchaseValue}
                    purchaseConditions={purchaseConditions}
                    couponDiscount={couponDiscount}
                    selectedPurchaseCondition={selectedPurchaseCondition}
                    isVerificationDialogOpen={isVerificationDialogOpen}
                    isPurchaseConfirmed={isPurchaseConfirmed}
                    authorizationStrategy={authorizationStrategy}
                    formattedCellphone={formattedCellphone}
                    togglePurchaseConditionSelected={this.togglePurchaseConditionSelected}
                    onKeyPressTableRow={this.onKeyPressTableRow}
                    openVerificationDialog={this.openVerificationDialog}
                    startVerificationProcess={this.startVerificationProcess}
                    handleKeyPressCancel={this.handleKeyPressCancel}
                    cancelPurchase={this.cancelPurchase}
                    finishPurchase={this.finishPurchase}
                    sourceProduct={sourceProduct}
                    downPayment={this.state.downPayment}
                    purchaseValueMinusDownPayment={this.state.purchaseValueMinusDownPayment}
                    principal={this.state.principal}
                    productPrice={this.state.productPrice}
                    contractId={this.state.contractId}
                    proposalId={
                      this.state?.purchaseConditions[this.state?.selectedPurchaseCondition]
                        ?.proposalId
                    }
                    transactionalCreditEvaluationId={this.state.transactionCreditEvaluation?.id}
                    productId={this.state.productId}
                    verificationMethod={this.state.verificationMethod}
                  />
                </>
              ) : (
                <>
                  {purchaseConditions.length > 0 && (
                    <>
                      <Divider />
                      <table ref={this.tableRef}>
                        <thead tabIndex={-1}>
                          <tr>
                            <th style={tableStyleHeaderCell}>Qtd. Parcelas</th>
                            <th style={tableStyleHeaderCell}>Juros (C.E.T)</th>
                            <th style={tableStyleHeaderCell}>IOF</th>
                            <th style={tableStyleHeaderCell}>Valor Parcelas</th>
                            <th
                              style={
                                couponDiscount > 0
                                  ? tableStyleHeaderWithoutDiscountCell
                                  : tableStyleHeaderCell
                              }
                            >
                              Valor Total
                            </th>
                            {couponDiscount > 0 && (
                              <th style={tableStyleHeaderDiscountCell}>Valor com Desconto</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {purchaseConditions.map((i, idx) => (
                            <tr
                              tabIndex={1}
                              style={{
                                ...(i.isSelected && {
                                  backgroundColor: '#106ba3', // blue
                                  color: '#00000',
                                  fontWeight: 'bold',
                                }),
                                ...(i.isFocused &&
                                  !i.isSelected && {
                                    backgroundColor: '#ededed', // grey
                                  }),
                                ...(i.isFocused &&
                                  i.isSelected && {
                                    backgroundColor: '#ededed', // grey
                                  }),
                                marginBottom: '16px',
                                outline: 'none',
                                cursor: 'pointer',
                              }}
                              key={idx}
                              onClick={this.togglePurchaseConditionSelected.bind(this, idx)}
                              onKeyDown={this.onKeyPressTableRow.bind(this, idx)}
                            >
                              <td style={tableStyleRowCell}>{i.installments}</td>
                              <td style={{ ...tableStyleRowCell, ...{ fontSize: '16px' } }}>{`${(
                                i.interestRate * 100
                              ).toFixed(2)}% (${(i.cet * 100).toFixed(2)}%)`}</td>
                              <td style={{ ...tableStyleRowCell, ...{ fontSize: '16px' } }}>
                                {formatBRL(i.iof)}
                              </td>
                              <td style={tableStyleRowCell}>{formatBRL(i.installmentValue)}</td>
                              <td
                                style={
                                  couponDiscount > 0
                                    ? tableStyleRowWithoutDiscountCell
                                    : tableStyleRowCell
                                }
                              >
                                {formatBRL(
                                  i.installmentValue * i.installments + Number(couponDiscount)
                                )}
                              </td>
                              {couponDiscount > 0 && (
                                <td style={tableStyleRowDiscountCell}>
                                  {formatBRL(i.installmentValue * i.installments)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div style={{ ...formActionsStyle, ...{ marginTop: '16px' } }}>
                    <button
                      type="button"
                      className="bp3-button bp3-intent-primary bp3-right-icon-tick"
                      disabled={selectedPurchaseCondition === -1}
                      style={formItemStyle}
                      tabIndex={1}
                      onClick={this.openVerificationDialog}
                    >
                      Aceitar
                    </button>
                  </div>
                  <PurchaseConfirmationPopup
                    purchaseConditions={purchaseConditions}
                    selectedPurchaseCondition={selectedPurchaseCondition}
                    isVerificationDialogOpen={isVerificationDialogOpen}
                    isPurchaseConfirmed={isPurchaseConfirmed}
                    authorizationStrategy={authorizationStrategy}
                    formattedCellphone={formattedCellphone}
                    startVerificationProcess={this.startVerificationProcess}
                    handleKeyPressCancel={this.handleKeyPressCancel}
                    cancelPurchase={this.cancelPurchase}
                    finishPurchase={this.finishPurchase}
                    sourceProduct={sourceProduct}
                    contractId={this.state.contractId}
                    proposalId={
                      this.state?.purchaseConditions[this.state?.selectedPurchaseCondition]
                        ?.proposalId
                    }
                    transactionalCreditEvaluationId={this.state.transactionCreditEvaluation?.id}
                    productId={this.state.productId}
                    verificationMethod={this.state.verificationMethod}
                  />
                </>
              ))}
          </Card>
        </div>
      </div>
    )
  }
}

const Header = styled.div`
  display: flex;
  margin-top: 70px;
  margin-left: 30px;

  @media only screen and (max-width: 420px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const ChangeCaptureMethod = styled.a`
  color: black;
  text-align: left;
  text-decoration: underline;
`

const QRCodeCaptureMethodTooltipLink = styled.a`
  text-decoration: underline;
`

const ChangeCaptureMethodContainer = styled.div`
  text-align: left;
`

export default connect()(Purchases)

const SmartphoneContainer = styled.div`
  padding: 12px;

  display: flex;
  flex-direction: column;
  text-align: left;
  height: calc(100% - 56px);

  @media (max-width: 480px) {
    margin: 0px 8px 0px 8px;
    padding: 10px 8px 20px 8px;
  }
  justify-content: space-between;
`
