export const startCountdown = (initialSeconds: number, onTick: (seconds: number) => void) => {
  let countDownSeconds = initialSeconds

  const timer = setInterval(() => {
    countDownSeconds -= 1
    onTick(countDownSeconds)

    if (countDownSeconds <= 0) {
      clearInterval(timer)
    }
  }, 1000)

  return () => clearInterval(timer)
}
