import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import LocalStorageWrapper, {
  LocalStorageItem,
} from '../../../common/assets/utils/LocalStorageWrapper'
import { bffParceirosApiService } from '../../../services/bff'
import { IOperator } from '../../../services/bff/coordinator/dto/Operators.dto'
import { OperatorPerformanceInfoResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import operatorSliceReducer, {
  FetchOperatorPerformanceInfoPayload,
  IFetchOperatorPayload,
} from './operator.reducer'
import { selectState } from '../selectors'

function* fetchOperator(action: PayloadAction<IFetchOperatorPayload>) {
  const { operatorId } = action.payload

  try {
    const response: IOperator = yield bffParceirosApiService.coordinator.fetchOperatorById(
      operatorId
    )

    LocalStorageWrapper.setItem(LocalStorageItem.OPERATOR, response)

    yield put(operatorSliceReducer.actions.fetchOperatorSuccess(response))
  } catch (error) {
    yield put(operatorSliceReducer.actions.fetchOperatorError(error))
  }
}

function* fetchOperatorPerformanceInfo(action: PayloadAction<FetchOperatorPerformanceInfoPayload>) {
  try {
    const operatorId = action.payload.operatorId
    if (!operatorId) return

    const operatorPerformance = LocalStorageWrapper.getOperatorPerformance(operatorId)
    if (operatorPerformance) {
      yield put(
        operatorSliceReducer.actions.fetchOperatorPerformanceInfoSuccess({ operatorPerformance })
      )
    }

    const response: OperatorPerformanceInfoResponse = yield bffParceirosApiService.operatorServiceCenter.fetchOperatorPerformance(
      operatorId
    )

    LocalStorageWrapper.setOperatorPerformance(operatorId, response.operatorPerformance)

    yield put(operatorSliceReducer.actions.fetchOperatorPerformanceInfoSuccess(response))
  } catch (error) {
    yield put(operatorSliceReducer.actions.fetchOperatorPerformanceInfoError(error))
  }
}

const operatorSaga = [
  takeLatest(operatorSliceReducer.actions.fetchOperator, fetchOperator),
  takeLatest(
    operatorSliceReducer.actions.fetchOperatorPerformanceInfo,
    fetchOperatorPerformanceInfo
  ),
]

export default operatorSaga
