import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { bffParceirosApiService } from '../../../services/bff'

import PurchaseVerificationSliceReducer from './purchase-verification.reducer'
import SmartphoneSaleSliceReducer from './purchase-verification.reducer'
import {
  SmartphoneSaleIMEIVerifyRequest,
  SmartphoneSaleSubmitLockRequest,
  SmartphoneSaleConfirmLockRequest,
} from '../../../services/bff/smartphone-sale/dtos/smartphone-sale-credit-evaluation.dto'
import { toaster } from '../../../App'

function* verifySmartphoneIMEI(action: PayloadAction<SmartphoneSaleIMEIVerifyRequest>) {
  const { contractId, imei, productId, transactionalCreditEvaluationId } = action.payload

  try {
    const smartphoneSaleImeiVerifyResponse = yield bffParceirosApiService.smartphoneSaleCredit.smartphoneSaleIMEIVerify(
      {
        contractId,
        transactionalCreditEvaluationId,
        imei,
        productId,
      }
    )

    yield put(
      PurchaseVerificationSliceReducer.actions.verifySmartphoneIMEISuccess(
        smartphoneSaleImeiVerifyResponse
      )
    )
  } catch (err) {
    yield put(PurchaseVerificationSliceReducer.actions.verifySmartphoneIMEIError(err))
  }
}

function* submitSmartphoneSaleLock(action: PayloadAction<SmartphoneSaleSubmitLockRequest>) {
  const { contractId, transactionalCreditEvaluationId } = action.payload

  try {
    yield bffParceirosApiService.smartphoneSaleCredit.submitSmartphoneSaleLock({
      contractId,
      transactionalCreditEvaluationId,
    })
    yield put(SmartphoneSaleSliceReducer.actions.submitSmartphoneSaleLockSuccess())
  } catch (err) {
    yield put(SmartphoneSaleSliceReducer.actions.submitSmartphoneSaleLockError(err))
  }
}

function* confirmSmartphoneSaleLock(action: PayloadAction<SmartphoneSaleConfirmLockRequest>) {
  const { contractId, transactionalCreditEvaluationId } = action.payload

  try {
    yield bffParceirosApiService.smartphoneSaleCredit.smartphoneSaleConfirmLock({
      contractId,
      transactionalCreditEvaluationId,
    })
    yield put(SmartphoneSaleSliceReducer.actions.confirmSmartphoneSaleLockSuccess())
    toaster.showSuccessToast('Mensagem de ativação enviada com sucesso', 4) // TODO: remove this
  } catch (err) {
    yield put(SmartphoneSaleSliceReducer.actions.confirmSmartphoneSaleLockError(err))
  }
}

const smartphoneSaleSaga = [
  takeLatest(SmartphoneSaleSliceReducer.actions.verifySmartphoneIMEI, verifySmartphoneIMEI),
  takeLatest(SmartphoneSaleSliceReducer.actions.submitSmartphoneSaleLock, submitSmartphoneSaleLock),
  takeLatest(
    SmartphoneSaleSliceReducer.actions.confirmSmartphoneSaleLock,
    confirmSmartphoneSaleLock
  ),
]

export default smartphoneSaleSaga
