import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import {
  CreateOriginationCodeResponse,
  GetOriginationCodeQueryParams,
  VerificationProcessResponse,
} from '../../../services/bff/originations/dto/OriginationCode.dto'
import {
  IPatchVerifyOriginationCode,
  IPostCreateOriginationVerificationBody,
} from '../../../services/bff/originations/dto/PhoneVerificationRecord.dto'
import { VerificationStatus } from '../../../services/bff/originations/dto/verification.enum'

export interface RequestAnalystVerificationAction {
  proposalId: string
}

interface PurchaseVerification {
  status: VerificationStatus
  wasVerified: boolean
}

interface PurchaseVerificationState {
  isCreatingPurchaseVerification: boolean
  isCreatePurchaseVerificationError: boolean
  createdPurchaseVerification?: CreateOriginationCodeResponse

  isFetchingPurchaseVerification: boolean
  purchaseVerification?: PurchaseVerification
  isFetchingPurchaseVerificationError: boolean

  isVerifyingPurchaseVerification: boolean
  isVerifyPurchaseVerificationError: boolean
  isPurchaseVerificationVerified: boolean

  isRequestingAnalystVerification: boolean
}

const initialState: PurchaseVerificationState = {
  createdPurchaseVerification: undefined,
  isCreatingPurchaseVerification: false,
  isCreatePurchaseVerificationError: false,
  isFetchingPurchaseVerification: false,
  purchaseVerification: undefined,
  isFetchingPurchaseVerificationError: false,
  isVerifyingPurchaseVerification: false,
  isVerifyPurchaseVerificationError: false,
  isPurchaseVerificationVerified: false,
  isRequestingAnalystVerification: false,
}

const PurchaseVerificationSliceReducer = createSlice({
  name: 'purchaseVerification',
  initialState,
  reducers: {
    createPurchaseVerification: (
      state,
      action: PayloadAction<IPostCreateOriginationVerificationBody>
    ) => {
      return {
        ...state,
        isCreatingPurchaseVerification: true,
        isCreatePurchaseVerificationError: false,
        isVerifyPurchaseVerificationError: false,
      }
    },
    createPurchaseVerificationSuccess: (
      state,
      action: PayloadAction<CreateOriginationCodeResponse>
    ) => {
      return {
        ...state,
        isCreatingPurchaseVerification: false,
        createdPurchaseVerification: action.payload,
      }
    },
    createPurchaseVerificationError: (state, action: PayloadAction<AxiosError>) => {
      return {
        ...state,
        isCreatingPurchaseVerification: false,
        isCreatePurchaseVerificationError: true,
      }
    },
    fetchPurchaseVerification: (state, action: PayloadAction<GetOriginationCodeQueryParams>) => {
      return {
        ...state,
        isFetchingPurchaseVerification: true,
        isFetchingPurchaseVerificationError: false,
      }
    },
    fetchPurchaseVerificationSuccess: (
      state,
      action: PayloadAction<VerificationProcessResponse>
    ) => {
      const isPurchaseVerificationVerified = action.payload.wasVerified

      return {
        ...state,
        purchaseVerification: action.payload,
        isFetchingPurchaseVerification: false,
        isPurchaseVerificationVerified,
      }
    },
    fetchPurchaseVerificationError: (state, action: PayloadAction<AxiosError>) => {
      return {
        ...state,
        isFetchingPurchaseVerification: false,
        isFetchingPurchaseVerificationError: true,
      }
    },
    verifyPurchaseVerification: (state, action: PayloadAction<IPatchVerifyOriginationCode>) => {
      return {
        ...state,
        isVerifyingPurchaseVerification: true,
        isVerifyPurchaseVerificationError: false,
      }
    },
    verifyPurchaseVerificationSuccess: (state, action: PayloadAction<void>) => {
      return {
        ...state,
        isVerifyingPurchaseVerification: false,
        isPurchaseVerificationVerified: true,
      }
    },
    verifyPurchaseVerificationError: (state, action: PayloadAction<AxiosError>) => {
      return {
        ...state,
        isVerifyingPurchaseVerification: false,
        isVerifyPurchaseVerificationError: true,
      }
    },
    requestAnalystVerification: (
      state,
      action: PayloadAction<RequestAnalystVerificationAction>
    ) => {
      return {
        ...state,
        isRequestingAnalystVerification: true,
      }
    },
    requestAnalystVerificationSuccess: (state) => {
      return {
        ...state,
        isRequestingAnalystVerification: false,
        purchaseVerification: {
          status: VerificationStatus.MANUAL,
          wasVerified: false,
        },
      }
    },
    requestAnalystVerificationError: (state) => {
      return {
        ...state,
        isRequestingAnalystVerification: false,
      }
    },
    resetPurchaseVerification: () => initialState,
  },
})

export default PurchaseVerificationSliceReducer
