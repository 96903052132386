import { Dialog } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, batch } from 'react-redux'

import { formActionsStyle } from '../../cash-register/styles'
import PurchaseVerificationSliceReducer from '../../redux/reducers/purchase-verification/purchase-verification.reducer'
import {
  SourceProduct,
  VerificationMethodTypeEnum,
} from '../../services/bff/coordinator/dto/Contracts.dto'
import { white } from '../../ui/utils/_colors'
import { LivenessAnalystModal } from './LivenessAnalystModal'
import { PurchaseConditions } from './PurchaseInstallmentsOptionsCard'
import PurchaseConfirmationDetails from './PurchaseConfirmationDetails'
import SmartphoneLockConfiguration from './SmartphoneLockConfiguration'
import SmartphoneSaleSliceReducer from '../../redux/reducers/smartphone-sale/purchase-verification.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { TokenVerificationModal } from './TokenVerificationModal'
import { toaster } from '../../App'
import { PurchaseVerificationErrors } from '../../common/ErrorStrings'
import { VerificationMethod } from '../../services/bff/originations/dto/verification.enum'
import styled from 'styled-components'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'

interface Props {
  purchaseConditions: PurchaseConditions[]
  selectedPurchaseCondition: number
  isVerificationDialogOpen: boolean
  isPurchaseConfirmed: boolean
  authorizationStrategy: string
  formattedCellphone: string
  startVerificationProcess: () => void
  handleKeyPressCancel: (event: React.KeyboardEvent) => void
  cancelPurchase: () => void
  finishPurchase: () => void
  sourceProduct?: string
  contractId: string
  proposalId: string
  transactionalCreditEvaluationId?: string
  productId?: string
  verificationMethod?: VerificationMethodTypeEnum
}

export const PurchaseConfirmationPopup: React.FC<Props> = (props) => {
  const {
    purchaseConditions,
    selectedPurchaseCondition,
    isVerificationDialogOpen,
    isPurchaseConfirmed,
    authorizationStrategy,
    formattedCellphone,
    startVerificationProcess,
    handleKeyPressCancel,
    cancelPurchase,
    finishPurchase,
    sourceProduct,
    contractId,
    proposalId,
    transactionalCreditEvaluationId,
    productId,
    verificationMethod,
  } = props

  let nextButtonLabel = 'Finalizar'
  let cancelButtonLabel = 'Cancelar'
  if (sourceProduct == SourceProduct.SMARTPHONE_SALE) {
    nextButtonLabel = 'Próximo'
    cancelButtonLabel = 'Cancelar'
  }

  const dispatch = useDispatch()
  const [showSmartphoneConfiguration, setShowSmartphoneConfiguration] = useState<boolean>(false)
  const {
    isPurchaseVerificationVerified,
    isCreatePurchaseVerificationError,
    createdPurchaseVerification,
    isCreatingPurchaseVerification,
  } = useTypedSelector((state) => ({
    isPurchaseVerificationVerified: state.purchaseVerification.isPurchaseVerificationVerified,
    isCreatePurchaseVerificationError: state.purchaseVerification.isCreatePurchaseVerificationError,
    createdPurchaseVerification: state.purchaseVerification.createdPurchaseVerification,
    isCreatingPurchaseVerification: state.purchaseVerification.isCreatingPurchaseVerification,
  }))

  useEffect(() => {
    if (!isVerificationDialogOpen) {
      batch(() => {
        dispatch(PurchaseVerificationSliceReducer.actions.resetPurchaseVerification())
        dispatch(SmartphoneSaleSliceReducer.actions.resetSmartphoneSale())
        setShowSmartphoneConfiguration(false)
      })
    }

    if (isVerificationDialogOpen) {
      const communicationChannel =
        authorizationStrategy === 'ANALYST_VERIFICATION' ? 'ANALYST_CALL' : 'SMS'

      dispatch(
        PurchaseVerificationSliceReducer.actions.createPurchaseVerification({
          proposalId,
          communicationChannel,
        })
      )
    }
  }, [isVerificationDialogOpen])

  useEffect(() => {
    if (isCreatePurchaseVerificationError) {
      toaster.showErrorToast(PurchaseVerificationErrors.CREATE_PURCHASE_VERIFICATION_ERROR)
    }
  }, [isCreatePurchaseVerificationError])

  const handleFinishPurchase = () => {
    if (
      sourceProduct == SourceProduct.SMARTPHONE_SALE &&
      isPurchaseVerificationVerified &&
      !showSmartphoneConfiguration
    ) {
      setShowSmartphoneConfiguration(true)
    } else {
      finishPurchase()
    }
  }

  if (isCreatingPurchaseVerification && !createdPurchaseVerification) {
    return (
      <div style={{ ...formActionsStyle }}>
        <LoadingContainer>
          <LoadingSpinner text="Carregando verificação..." />
        </LoadingContainer>
      </div>
    )
  }

  return (
    <div style={{ ...formActionsStyle }}>
      <StyledDialog isOpen={isVerificationDialogOpen} onClose={() => {}}>
        {!isPurchaseConfirmed && selectedPurchaseCondition !== -1 && (
          <div onKeyDown={(event: React.KeyboardEvent) => handleKeyPressCancel(event)}>
            <PurchaseConfirmationDetails
              purchaseValue={
                purchaseConditions[selectedPurchaseCondition].installments *
                purchaseConditions[selectedPurchaseCondition].installmentValue
              }
              installmentsValue={purchaseConditions[selectedPurchaseCondition].installmentValue}
              numberOfInstallments={purchaseConditions[selectedPurchaseCondition].installments}
              sourceProduct={sourceProduct as SourceProduct}
              contractId={contractId}
              productId={productId}
              transactionalCreditEvaluationId={transactionalCreditEvaluationId}
              cancelPurchase={cancelPurchase}
              nextStep={() => startVerificationProcess()}
            />
          </div>
        )}
        {isPurchaseConfirmed && (
          <>
            {showSmartphoneConfiguration ? (
              <SmartphoneLockConfiguration
                handleKeyPressCancel={handleKeyPressCancel}
                nextStep={handleFinishPurchase}
                cancelPurchase={cancelPurchase}
                contractId={contractId}
                transactionalCreditEvaluationId={transactionalCreditEvaluationId!}
              />
            ) : createdPurchaseVerification?.verificationMethod ==
              VerificationMethod.LIVENESS_WITH_ANALYST ? (
              <LivenessAnalystModal
                formattedCellphone={formattedCellphone}
                isOpen={isVerificationDialogOpen}
                onCancel={cancelPurchase}
                handleKeyPressCancel={handleKeyPressCancel}
                onNextStep={handleFinishPurchase}
                proposalId={proposalId}
                confirmButtonLabel={nextButtonLabel}
                cancelButtonLabel={cancelButtonLabel}
              />
            ) : (
              <TokenVerificationModal
                isOpen={isVerificationDialogOpen}
                onCancel={cancelPurchase}
                handleKeyPressCancel={handleKeyPressCancel}
                onNextStep={handleFinishPurchase}
                proposalId={proposalId}
                formattedCellphone={formattedCellphone}
                confirmButtonLabel={nextButtonLabel}
                cancelButtonLabel={cancelButtonLabel}
              />
            )}
          </>
        )}
      </StyledDialog>
    </div>
  )
}

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
`

const StyledDialog = styled(Dialog)`
  background-color: ${white.primary};
  width: auto;
  padding: 0px;
`
export default PurchaseConfirmationPopup
