import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import LocalStorageWrapper, { LocalStorageItem } from '../../../common/assets/utils/LocalStorageWrapper'
import { IncentiveCategories } from '../../../pages/OperatorServiceCenter/enums/operator-service-center-incentives.enum'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import { OperatorServiceCenterBorrowerResponse, OperatorServiceCenterOptions } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import { APPLICATION_STATUS, BorrowerStatus } from '../application/application.types'
import { Configurations } from '../configurations/configurations.reducer'
import { Role } from '../../../common/constants'

export type OperatorServiceCenterState = {
  page: OPERATOR_SERVICE_CENTER_PAGES
  isFetchingBorrower: boolean
  borrower?: OperatorServiceCenterBorrowerResponse
  borrowerRestriction?: string
  error?: AxiosError
  applicationReevaluationStatus?: APPLICATION_STATUS
  preApplicationStatus?: BorrowerStatus
  document?: string
  isReevaluatingBorrower: boolean
  isFetchingHomePageInfo: boolean
  options?: OperatorServiceCenterOptions
  identifiedOperator?: SetIdentifiedOperatorActionPayload
  configurations?: Configurations
}

const initialState: OperatorServiceCenterState = {
  page: OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE,
  isFetchingBorrower: false,
  isFetchingHomePageInfo: false,
  isReevaluatingBorrower: false,
  applicationReevaluationStatus: undefined,
  borrower: undefined,
  borrowerRestriction: undefined,
  error: undefined,
  preApplicationStatus: undefined,
  options: undefined,
  identifiedOperator: undefined,
  configurations: LocalStorageWrapper.getItem(LocalStorageItem.CONFIGURATIONS),
}

export interface IFetchBorrowerPayload {
  document: string
  storeId: string
  operatorId?: string
}

export interface ReevaluateBorrowerPayload {
  document: string
  borrowerId: string
  storeId: string
}

export interface SetIdentifiedOperatorActionPayload {
  id: string
  name: string
  document: string
  phoneNumber: string
  role: Role
}

export interface ReevaluatedBorrower {
  borrower: OperatorServiceCenterBorrowerResponse
  reevaluationStatus: APPLICATION_STATUS
}

export interface OperatorServiceCenterSetupPayload {
  configurations?: Configurations
}

const OperatorServiceCenterSliceReducer = createSlice({
  name: 'operatorServiceCenter',
  initialState,
  reducers: {
    setup: (state, action: PayloadAction<OperatorServiceCenterSetupPayload>) => {
      return { ...state, configurations: action.payload.configurations }
    },
    setPage: (state, action: PayloadAction<OPERATOR_SERVICE_CENTER_PAGES>) => {
      const page = action.payload
      return { ...state, page }
    },
    setIdentifiedOperator: (state, action: PayloadAction<SetIdentifiedOperatorActionPayload>) => {
      return { ...state, identifiedOperator: action.payload }
    },
    setBorrowerRestriction: (state, action: PayloadAction<string>) => {
      const borrowerRestriction = action.payload
      return { ...state, borrowerRestriction }
    },
    setDocument: (state, action: PayloadAction<string>) => {
      return { ...state, document: action.payload }
    },
    fetchBorrower: (state, action: PayloadAction<IFetchBorrowerPayload>) => {
      const { document, storeId } = action.payload
      return {
        ...state,
        document,
        storeId,
        isFetchingBorrower: true,
      }
    },
    fetchBorrowerSuccess: (state, action: PayloadAction<OperatorServiceCenterBorrowerResponse>) => {
      const borrower = action.payload
      const incentiveCategory = borrower?.credit?.incentiveCategory === IncentiveCategories.SAME_DAY ? IncentiveCategories.OURO : borrower?.credit?.incentiveCategory

      return {
        ...state,
        borrower: {
          ...borrower,
          credit: {
            ...borrower?.credit,
            incentiveCategory,
          },
        },
        isFetchingBorrower: false,
        preApplicationStatus: action.payload?.application?.status,
        options: action.payload?.options,
      }
    },
    fetchBorrowerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        error,
        isFetchingBorrower: false,
      }
    },

    reevaluateBorrower: (state, action: PayloadAction<ReevaluateBorrowerPayload>) => {
      const { borrowerId, storeId } = action.payload
      return {
        ...state,
        borrowerId,
        storeId,
        isReevaluatingBorrower: true,
      }
    },
    reevaluateBorrowerSuccess: (state, action: PayloadAction<ReevaluatedBorrower>) => {
      const { borrower, reevaluationStatus } = action.payload
      return {
        ...state,
        borrower,
        isReevaluatingBorrower: false,
        applicationReevaluationStatus: reevaluationStatus,
      }
    },
    reevaluateBorrowerError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        error,
        isReevaluatingBorrower: false,
      }
    },
    resetRequest: state => {
      return { ...initialState, configurations: LocalStorageWrapper.getItem(LocalStorageItem.CONFIGURATIONS) }
    },
  },
})

export default OperatorServiceCenterSliceReducer
