import { isSmartphoneSaleAllowed, isTransactionalCreditAllowed } from '../../common/AccessHelper'
import { Role } from '../../common/constants'
import { navigateToPath } from '../../common/Navigation'
import { isOperatorSalesRole } from '../../common/RoleValidators'
import { Configurations } from '../../redux/reducers/configurations/configurations.reducer'
import { PagesPathEnum } from '../enums/pages-path.enum'

export class OperatorCenterHelper {
  static redirectToOperatorHome(configurations: Configurations, operatorRole: Role): void {
    if (operatorRole === Role.CASHIER) return navigateToPath(PagesPathEnum.CASH_REGISTER)

    const operatorSalesRoleHomePath = this.getOperatorSalesRoleHomePath(configurations, operatorRole)

    if (operatorSalesRoleHomePath) return navigateToPath(operatorSalesRoleHomePath)

    return navigateToPath(PagesPathEnum.HOME_PAGE)
  }

  static getOperatorSalesRoleHomePath(configurations: Configurations, operatorRole: Role): PagesPathEnum | null {
    if(isSmartphoneSaleAllowed(configurations)) {
      return PagesPathEnum.SMARTPHONE_SALE
    }
    
    if (isOperatorSalesRole(operatorRole)) {
      return isTransactionalCreditAllowed(configurations) ? PagesPathEnum.TRANSACTIONAL_CREDIT_PAGE : PagesPathEnum.OPERATOR_SERVICE_CENTER
    }

    return null
  }

  static shouldShowOperatorIdentification(configurations?: Configurations, identifiedOperatorId?: string): boolean {
    return !!(configurations?.operator?.isStoreLogin && !identifiedOperatorId)
  }

  static shouldDisableOperatorEdition(configurations?: Configurations): boolean {
    return !!(configurations?.operator?.isStoreLogin)
  }

  static isStoreLogin(configurations?: Configurations): boolean {
    return !!(configurations?.operator?.isStoreLogin)
  }
}
