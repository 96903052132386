export type IPhoneCommunicationChannel = 'SMS' | 'WHATSAPP' | 'PHONE_CALL' | 'ANALYST_CALL'

export enum PhoneCommunicationChannel {
  SMS = 'SMS',
  WHATSAPP = 'WHATSAPP',
  PHONE_CALL = 'PHONE_CALL',
  ANALYST_CALL = 'ANALYST_CALL',
}

export interface IPostCreateOriginationVerificationBody {
  communicationChannel?: IPhoneCommunicationChannel
  proposalId: string
}

export interface IBaseRecord {
  id: string
  createdOn: string
  modifiedOn: string
}

export type IRequestedByProcessRecord = 'PURCHASE'
export type IPhoneCommunicationChannelRecord = 'SMS' | 'WHATSAPP' | 'PHONE_CALL' | 'ANALYST_CALL'
export type IVerificationMethodRecord = 'PURCHASE_TOKEN' | 'DEFAULT'

export interface PhoneVerificationRecordDto extends IBaseRecord {
  verificationCode: string
  wasVerified: boolean
  ipAddress?: string
  borrowerId?: string
  phoneId?: string
  wasUsed: boolean
  requestedByProcess: IRequestedByProcessRecord
  operatorId?: string
  authorizedBy: number
  communicationChannel: IPhoneCommunicationChannelRecord
  proposalId?: string
  verificationMethod?: IVerificationMethodRecord
}

export interface IPatchVerifyOriginationCode {
  proposalId: string
  purchaseCode: string
}
