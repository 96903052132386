import moment from 'moment'
import 'moment/locale/pt-br'

export class DateUtils {
  static getFormattedMonthAndYear = (date: string) => {
    const formattedDate = moment(date).format('MMMM/YYYY')
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
  }

  static getDiffInSeconds = (date: string) => {
    const now = moment()
    const diffInSeconds = moment(date).diff(now, 'seconds')
    return diffInSeconds
  }

  static formatTimeToCountdown = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }
}
