import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import {
  SmartphoneSaleIMEIVerifyRequest,
  SmartphoneSaleImeiVerifyResponse,
  SmartphoneSaleSubmitLockRequest,
  SmartphoneSaleConfirmLockRequest,
} from '../../../services/bff/smartphone-sale/dtos/smartphone-sale-credit-evaluation.dto'
import { getIMEIVerificationError } from '../../../common/ErrorStrings'

export enum SmartphoneIMEIVerificationStep {
  PENDING,
  VERIFIED,
}

interface SmartphoneSaleState {
  smartphoneIMEIVerificationStep: SmartphoneIMEIVerificationStep
  isVerifyingSmartphoneIMEILoading: boolean
  smartphoneBrand?: string
  verifySmartphoneIMEIError?: string

  isSubmittingSmartphoneSaleLockLoading: boolean
  submitSmartphoneSaleLockError?: string

  isConfirmingSmartphoneSaleLockLoading: boolean
  smartphoneSaleLockConfirmed: boolean
  confirmSmartphoneSaleLockError?: string
}

const initialState: SmartphoneSaleState = {
  smartphoneIMEIVerificationStep: SmartphoneIMEIVerificationStep.PENDING,
  isVerifyingSmartphoneIMEILoading: false,
  smartphoneBrand: undefined,
  verifySmartphoneIMEIError: undefined,

  isSubmittingSmartphoneSaleLockLoading: false,
  submitSmartphoneSaleLockError: undefined,

  isConfirmingSmartphoneSaleLockLoading: false,
  smartphoneSaleLockConfirmed: false,
  confirmSmartphoneSaleLockError: undefined,
}

const SmartphoneSaleSliceReducer = createSlice({
  name: 'smartphoneSale',
  initialState,
  reducers: {
    verifySmartphoneIMEI: (state, action: PayloadAction<SmartphoneSaleIMEIVerifyRequest>) => {
      return {
        ...state,
        isVerifyingSmartphoneIMEILoading: true,
        verifySmartphoneIMEIError: undefined,
      }
    },
    verifySmartphoneIMEISuccess: (
      state,
      action: PayloadAction<SmartphoneSaleImeiVerifyResponse>
    ) => {
      return {
        ...state,
        isVerifyingSmartphoneIMEILoading: false,
        smartphoneIMEIVerificationStep: SmartphoneIMEIVerificationStep.VERIFIED,
        smartphoneBrand: action.payload?.imeiDetails?.brand,
      }
    },
    verifySmartphoneIMEIError: (state, action: PayloadAction<AxiosError>) => {
      const errorMessage = getIMEIVerificationError(action.payload.response?.data?.error)

      return {
        ...state,
        isVerifyingSmartphoneIMEILoading: false,
        verifySmartphoneIMEIError: errorMessage,
      }
    },
    submitSmartphoneSaleLock: (state, action: PayloadAction<SmartphoneSaleSubmitLockRequest>) => {
      return {
        ...state,
        isSubmittingSmartphoneSaleLockLoading: true,
        submitSmartphoneSaleLockError: undefined,
      }
    },
    submitSmartphoneSaleLockSuccess: (state) => {
      return {
        ...state,
        isSubmittingSmartphoneSaleLockLoading: false,
      }
    },
    submitSmartphoneSaleLockError: (state, action: PayloadAction<AxiosError>) => {
      return {
        ...state,
        isSubmittingSmartphoneSaleLockLoading: false,
        submitSmartphoneSaleLockError: action.payload.message,
      }
    },
    confirmSmartphoneSaleLock: (state, action: PayloadAction<SmartphoneSaleConfirmLockRequest>) => {
      return {
        ...state,
        isConfirmingSmartphoneSaleLockLoading: true,
        smartphoneSaleLockConfirmed: false,
      }
    },
    confirmSmartphoneSaleLockSuccess: (state) => {
      return {
        ...state,
        isConfirmingSmartphoneSaleLockLoading: false,
        smartphoneSaleLockConfirmed: true,
        confirmSmartphoneSaleLockError: undefined,
      }
    },
    confirmSmartphoneSaleLockError: (state, action: PayloadAction<AxiosError>) => {
      return {
        ...state,
        isConfirmingSmartphoneSaleLockLoading: false,
        confirmSmartphoneSaleLockError: action.payload.message,
      }
    },
    resetSmartphoneSale: () => initialState,
  },
})

export default SmartphoneSaleSliceReducer
