import React, { useEffect, useState } from 'react'
import { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import LoadingSpinner from '../../atoms/Loading/LoadingSpinner'
import LocalStorageWrapper, { LocalStorageItem } from '../../common/assets/utils/LocalStorageWrapper'
import { validate_cpf } from '../../common/Validators'
import { bffParceirosApiService } from '../../services/bff'
import { black, gray, green, red } from '../../ui/utils/_colors'
import DocumentUtils from '../../utils/document.utils'
import NumberField from '../Application/NumberField'
import PhoneUtils from '../../utils/phone.utils'
import { Role } from '../../common/constants'

export interface MaskedOperator {
  id: string
  name: string
  document: string
  phoneNumber: string
  role: Role
}

const MAX_NUMBER_OF_OPERATORS_IN_RECENT_LIST = 5
const EMPTY_DOCUMENT = ''

interface OperatorIdentificationProps {
  onSelectedOperator: (operator: MaskedOperator) => void
}

export const OperatorIdentification = ({ onSelectedOperator }: OperatorIdentificationProps) => {
  const [operatorDocument, setOperatorDocument] = useState<string>(EMPTY_DOCUMENT)
  const [isLoadingOperator, setIsLoadingOperator] = useState<boolean>(false)
  const [selectedOperator, setSelectedOperator] = useState<string | null>(null)
  const [recentOperators, setRecentOperators] = useState<MaskedOperator[]>([])
  const [documentNotFoundError, setDocumentNotFoundError] = useState<boolean>(false)
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false)

  useEffect(() => {
    const storedOperators = LocalStorageWrapper.getItem(LocalStorageItem.RECENT_OPERATORS_IDENTIFICATION)
    setRecentOperators(storedOperators || [])
  }, [])

  // TODO(lucas.citolin): I was unable to make the bottom button go up when the keyboard is open by using CSS, so I had to use this workaround.
  useEffect(() => {
    const handleFocus = () => {
      setIsKeyboardOpen(true)
    }

    const handleBlur = () => {
      setTimeout(() => {
        setIsKeyboardOpen(false)
      }, 100)
    }

    // Select all input fields
    const inputs = document.querySelectorAll('input, textarea')
    inputs.forEach(input => {
      input.addEventListener('focus', handleFocus)
      input.addEventListener('blur', handleBlur)
    })

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', handleFocus)
        input.removeEventListener('blur', handleBlur)
      })
    }
  }, [])

  const handleDocumentChange = (values: NumberFormatValues) => {
    setSelectedOperator(null)
    setOperatorDocument(values.value)
    setDocumentNotFoundError(false)
  }

  const handleOperatorSelect = (selectedOperatorDocument: string) => {
    setSelectedOperator(selectedOperatorDocument)
    setOperatorDocument(EMPTY_DOCUMENT)
    setDocumentNotFoundError(false)
  }

  const saveOperatorInRecentsList = async (operatorToSaveInRecentList: MaskedOperator) => {
    // Remove duplicates and add new operator at the beginning
    const updatedOperators = [operatorToSaveInRecentList, ...recentOperators.filter(c => c.document !== operatorToSaveInRecentList.document)].slice(0, MAX_NUMBER_OF_OPERATORS_IN_RECENT_LIST)

    setRecentOperators(updatedOperators)
    LocalStorageWrapper.setItem(LocalStorageItem.RECENT_OPERATORS_IDENTIFICATION, updatedOperators)
  }

  const handleNextClick = async () => {
    try {
      setDocumentNotFoundError(false)

      // If the operator is already in the recent list and selected it
      if (selectedOperator) {
        const chosenSelectedOperator = recentOperators?.find(operator => operator.document === selectedOperator)
        if (chosenSelectedOperator) {
          onSelectedOperator(chosenSelectedOperator)
        }

        return
      }

      // If the operator typed it's document
      if (validate_cpf(operatorDocument)) {
        setIsLoadingOperator(true)
        const operator = await bffParceirosApiService.operators.getOperatorIdentification(operatorDocument)
        const operatorToSaveInRecentList: MaskedOperator = {
          id: operator.id,
          name: operator.name,
          document: DocumentUtils.formatMaskedDocument(operator.document),
          phoneNumber: PhoneUtils.maskPhone(operator.phoneNumber),
          role: operator.role,
        }

        setIsLoadingOperator(false)
        await saveOperatorInRecentsList(operatorToSaveInRecentList)
        onSelectedOperator(operatorToSaveInRecentList)
      }
    } catch (error) {
      setDocumentNotFoundError(true)
      setIsLoadingOperator(false)
    }
  }

  const isDocumentValid = validate_cpf(operatorDocument)
  const isDocumentEmpty = operatorDocument.length > 0
  const documentErrorText = documentNotFoundError ? <ErrorText>Colaborador não encontrado</ErrorText> : <ErrorText>Informe seu CPF ou selecione um colaborador</ErrorText>

  if (isLoadingOperator) {
    return <LoadingSpinner />
  }

  return (
    <Container>
      <ContentContainer>
        <Title>Identificar colaborador</Title>
        <Subtitle>Identifique-se para receber comissões por cadastros e vendas realizadas.</Subtitle>
        <InputContainer>
          <NumberField
            label="CPF do colaborador"
            value={operatorDocument}
            type="tel"
            format="###.###.###-##"
            mask="_"
            placeholder="000.000.000-00"
            onValueChange={handleDocumentChange}
            style={{ width: '100%' }}
            onFocus={() => setSelectedOperator(null)}
            isValidated={isDocumentValid}
            error={(!isDocumentValid && isDocumentEmpty) || documentNotFoundError}
            helperText={((!isDocumentValid && isDocumentEmpty) || documentNotFoundError) && documentErrorText}
          />
        </InputContainer>

        {/* TODO(lucas.citolin): Generalize the footer so it doesn't need the following component */}
        {isKeyboardOpen && (
          <KeyboardOpenFooter>
            <NextButton onClick={() => handleNextClick()} disabled={!validate_cpf(operatorDocument) && !selectedOperator}>
              Próximo
            </NextButton>
          </KeyboardOpenFooter>
        )}
        {recentOperators.length > 0 && !isKeyboardOpen && (
          <RecentOperatorsSection>
            <SectionTitle>Colaboradores recentes</SectionTitle>
            <RadioGroup>
              {recentOperators.map(operator => (
                <RadioItem key={operator.document}>
                  <RadioInput type="radio" name="operator" checked={selectedOperator === operator.document} onChange={() => handleOperatorSelect(operator.document)} />
                  <RadioLabel>
                    <OperatorName>{operator.name}</OperatorName>
                    <OperatorDocument>{operator.document}</OperatorDocument>
                  </RadioLabel>
                </RadioItem>
              ))}
            </RadioGroup>
          </RecentOperatorsSection>
        )}
      </ContentContainer>

      {!isKeyboardOpen && (
        <Footer isKeyboardOpen={isKeyboardOpen}>
          <NextButton onClick={() => handleNextClick()} disabled={!validate_cpf(operatorDocument) && !selectedOperator}>
            Próximo
          </NextButton>
        </Footer>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: left;
`

const ContentContainer = styled.div`
  flex: 1;
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
`

const ErrorText = styled.div`
  color: ${red.rubi};
`

const Footer = styled.div<{ isKeyboardOpen: boolean }>`
  padding: 24px;
  background-color: white;
  position: sticky;
  bottom: ${(props: { isKeyboardOpen: boolean }) => (props.isKeyboardOpen ? '26vh' : '0px')};
`

const Title = styled.h1`
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 8px 0;
  color: ${black.primary};
`

const Subtitle = styled.p`
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  color: ${gray.scale700};
  margin: 0 0 32px 0;
`

const InputContainer = styled.div`
  margin-bottom: 32px;
  width: 100%;
`

const RecentOperatorsSection = styled.div`
  margin-bottom: 32px;
  text-align: left;
`

const SectionTitle = styled.h2`
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 16px 0;
  color: ${black.primary};
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const RadioItem = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f5f5f5;
  }
`

const RadioInput = styled.input`
  margin: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:checked {
    accent-color: ${green.dark2};
  }
`

const RadioLabel = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
`

const OperatorName = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  color: ${black.primary};
`

const OperatorDocument = styled.span`
  font-family: Roboto;
  font-weight: 600;
  font-size: 14px;
  color: ${gray.scale600};
`

const NextButton = styled.button`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: block;
  height: 48px;
  background-color: ${green.dark2};
  color: white;
  border: none;
  border-radius: 30px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    opacity: 0.9;
  }
`

const KeyboardOpenFooter = styled.div`
  padding: 16px 0;
  width: 100%;
`
