import React, { RefObject } from 'react'
import { NumberFormatValues } from 'react-number-format'
import styled from 'styled-components'
import { mobile } from '../../common/assets/utils/_breakpoints'
import {
  SourceProduct,
  VerificationMethodTypeEnum,
} from '../../services/bff/coordinator/dto/Contracts.dto'
import { IPhoneCommunicationChannel } from '../../services/bff/originations/dto/PhoneVerificationRecord.dto'
import PurchaseInstallmentsOptionsCard, {
  PurchaseConditions,
} from './PurchaseInstallmentsOptionsCard'
import PurchaseResumeCard from './PurchaseResumeCard'
import { PurchaseConfirmationPopup } from './PurchaseConfirmationPopup'

interface Props {
  purchaseValue: string
  purchaseConditions: PurchaseConditions[]
  couponDiscount: number
  selectedPurchaseCondition: number
  isVerificationDialogOpen: boolean
  isPurchaseConfirmed: boolean
  authorizationStrategy: string
  formattedCellphone: string
  togglePurchaseConditionSelected: (purchaseConditionIdx: number) => void
  onKeyPressTableRow: (index: number, event: React.KeyboardEvent) => void
  openVerificationDialog: () => void
  startVerificationProcess: () => void
  handleKeyPressCancel: (event: React.KeyboardEvent) => void
  cancelPurchase: () => void
  finishPurchase: () => void
  sourceProduct?: string
  downPayment?: number
  purchaseValueMinusDownPayment?: number
  principal: number
  productPrice?: number
  contractId: string
  proposalId: string
  verificationMethod?: VerificationMethodTypeEnum
  transactionalCreditEvaluationId?: string
  productId?: string
}

export const PurchaseContractOptions: React.FC<Props> = (props) => {
  const {
    purchaseValue,
    purchaseConditions,
    couponDiscount,
    selectedPurchaseCondition,
    isVerificationDialogOpen,
    isPurchaseConfirmed,
    authorizationStrategy,
    formattedCellphone,
    togglePurchaseConditionSelected,
    onKeyPressTableRow,
    openVerificationDialog,
    startVerificationProcess,
    handleKeyPressCancel,
    cancelPurchase,
    finishPurchase,
    sourceProduct,
    downPayment,
    purchaseValueMinusDownPayment,
    principal,
    productPrice,
    contractId,
    proposalId,
    transactionalCreditEvaluationId,
    productId,
    verificationMethod,
  } = props

  const hasFinancingOptions = purchaseConditions.length > 0
  const selectedInstallment = purchaseConditions.find(
    (condition) => condition.isSelected && condition.isFocused
  )

  return (
    <PurschaseOptionsSection>
      <PurschaseOptionsContainer>
        <PurchaseOptionsCard>
          {hasFinancingOptions && (
            <PurchaseInstallmentsOptionsCard
              togglePurchaseConditionSelected={togglePurchaseConditionSelected}
              onKeyPressTableRow={onKeyPressTableRow}
              purchaseConditions={purchaseConditions}
              sourceProduct={sourceProduct}
            />
          )}
        </PurchaseOptionsCard>
        <PurchaseResumeCardContainer>
          {selectedInstallment ? (
            <PurchaseResumeCard
              purchaseValue={purchaseValue}
              interestRate={selectedInstallment.interestRate}
              iof={selectedInstallment.iof}
              installments={selectedInstallment.installments}
              installmentValue={selectedInstallment.installmentValue}
              installmentValueWithoutInterest={
                sourceProduct == SourceProduct.INTEREST_PAID_BY_RETAILER
                  ? selectedInstallment.installmentValueWithoutInterest
                  : undefined
              }
              firstInstallmentDueDate={selectedInstallment.firstInstallmentDueDate}
              couponDiscount={couponDiscount}
              selectedPurchaseCondition={selectedPurchaseCondition}
              openVerificationDialog={openVerificationDialog}
              customerFeeRate={selectedInstallment.customerFeeRate}
              customerFeeValue={selectedInstallment.customerFeeValue}
              dueDateRule={selectedInstallment.dueDateRule}
              sourceProduct={sourceProduct}
              downPayment={downPayment}
              purchaseValueMinusDownPayment={purchaseValueMinusDownPayment}
              principal={principal}
              productPrice={productPrice}
            />
          ) : null}
        </PurchaseResumeCardContainer>
      </PurschaseOptionsContainer>
      <PurchaseConfirmationPopup
        purchaseConditions={purchaseConditions}
        selectedPurchaseCondition={selectedPurchaseCondition}
        isVerificationDialogOpen={isVerificationDialogOpen}
        isPurchaseConfirmed={isPurchaseConfirmed}
        authorizationStrategy={authorizationStrategy}
        formattedCellphone={formattedCellphone}
        startVerificationProcess={startVerificationProcess}
        handleKeyPressCancel={handleKeyPressCancel}
        cancelPurchase={cancelPurchase}
        finishPurchase={finishPurchase}
        sourceProduct={sourceProduct}
        contractId={contractId}
        proposalId={proposalId}
        transactionalCreditEvaluationId={transactionalCreditEvaluationId}
        productId={productId}
        verificationMethod={verificationMethod}
      />
    </PurschaseOptionsSection>
  )
}

export default PurchaseContractOptions

const PurschaseOptionsSection = styled.div`
  width: 100%;
  background-color: #eff4f0;
  border-radius: 20px;
  justify-content: space-around;

  @media (max-width: ${mobile}) {
    justify-content: center;
  }
`

const PurschaseOptionsContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${mobile}) {
    width: 100%;
    flex-wrap: wrap;
  }
`

const PurchaseOptionsCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const PurchaseResumeCardContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
`
