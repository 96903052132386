export default class PhoneUtils {
  static PHONE_NUMBER_LENGTH_WITH_PREFIX = 14

  static maskPhone(phoneNumber?: string): string {
    if(!phoneNumber) return ''

    if (phoneNumber.length != this.PHONE_NUMBER_LENGTH_WITH_PREFIX) return phoneNumber

    return `(${phoneNumber.slice(3, 5)}) ${phoneNumber.slice(5, 10)}-${phoneNumber.slice(10, 14)}`
  }
}
