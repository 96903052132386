import { LocalStorageOperatorPerformance } from './localStorage/OperatorPerformance.interfaces'

export enum LocalStorageItem {
  LOGGED_USER = 'logged_user',
  STORE = 'store',
  LOCAL_CASHIER_ID = 'local_cashier_id',
  CAPTURE_METHOD_CONFIG = 'capture_method_config',
  ID_TOKEN = 'id_token',
  USER = 'user',
  CONFIGURATIONS = 'configurations',
  OPERATOR = 'operator',
  PUBLIC_CONFIGURATIONS = 'public_configurations',
  RECENT_OPERATORS_IDENTIFICATION = 'recent_operators_identification',
  OPERATOR_PERFORMANCE = 'operator_performance',
}

export default class LocalStorageWrapper {
  static setItem(itemName: LocalStorageItem, value: any) {
    localStorage.setItem(itemName, JSON.stringify(value))
  }

  static getItem(itemName: LocalStorageItem) {
    return localStorage.getItem(itemName) ? JSON.parse(localStorage.getItem(itemName)!) : undefined
  }

  static getOperatorPerformance(operatorId: string): LocalStorageOperatorPerformance | null {
    const operatorPerformance = this.getItem(LocalStorageItem.OPERATOR_PERFORMANCE)
    return operatorPerformance ? operatorPerformance[operatorId] : null
  }

  static setOperatorPerformance(
    operatorId: string,
    operatorPerformance?: LocalStorageOperatorPerformance
  ): void {
    const localStorageOperatorPerformance = this.getItem(LocalStorageItem.OPERATOR_PERFORMANCE)
    const newLocalStorageOperatorPerformance = {
      ...localStorageOperatorPerformance,
      [operatorId]: operatorPerformance,
    }
    this.setItem(LocalStorageItem.OPERATOR_PERFORMANCE, newLocalStorageOperatorPerformance)
  }
}
