import React, { useEffect } from 'react'
import {
  InfoCard,
  Column,
  WarningIcon,
  InfoCardText,
  InfoIcon,
} from '../../cash-register/purchases-old/styles'
import { Icon, H5 } from '@blueprintjs/core'
import { Anchor } from '../../legacy-lib/design-kit-ui'
import styled from 'styled-components'
import { green, black, gray } from '../../ui/utils/_colors'
import { getConfig } from '../../config/config'
import { useTypedSelector } from '../../redux/reducers/selectors'
import PurchaseConfirmationButtons from './PurchaseConfirmationButtons'
import { useDispatch } from 'react-redux'
import SmartphoneSaleSliceReducer from '../../redux/reducers/smartphone-sale/purchase-verification.reducer'
import { keyframes } from 'styled-components'

interface SmartphoneLockConfigurationProps {
  contractId: string
  transactionalCreditEvaluationId: string
  nextStep: () => void
  cancelPurchase: () => void
  handleKeyPressCancel: (event: React.KeyboardEvent) => void
}

const SmartphoneLockConfiguration: React.FC<SmartphoneLockConfigurationProps> = ({
  nextStep,
  cancelPurchase,
  contractId,
  transactionalCreditEvaluationId,
  handleKeyPressCancel,
}) => {
  const dispatch = useDispatch()
  const {
    smartphoneBrand,
    confirmSmartphoneSaleLockError,
    smartphoneSaleLockConfirmed,
    isConfirmingSmartphoneSaleLockLoading,
    isSubmittingSmartphoneSaleLockLoading,
    submitSmartphoneSaleLockError,
  } = useTypedSelector((state) => ({
    smartphoneBrand: state.smartphoneSale.smartphoneBrand,
    confirmSmartphoneSaleLockError: state.smartphoneSale.confirmSmartphoneSaleLockError,
    smartphoneSaleLockConfirmed: state.smartphoneSale.smartphoneSaleLockConfirmed,
    isConfirmingSmartphoneSaleLockLoading:
      state.smartphoneSale.isConfirmingSmartphoneSaleLockLoading,
    isSubmittingSmartphoneSaleLockLoading:
      state.smartphoneSale.isSubmittingSmartphoneSaleLockLoading,
    submitSmartphoneSaleLockError: state.smartphoneSale.submitSmartphoneSaleLockError,
  }))

  const confirmSmartphoneSaleLock = () => {
    if (!smartphoneSaleLockConfirmed) {
      dispatch(
        SmartphoneSaleSliceReducer.actions.confirmSmartphoneSaleLock({
          contractId,
          transactionalCreditEvaluationId,
        })
      )
    }

    if (smartphoneSaleLockConfirmed) {
      nextStep()
    }
  }
  useEffect(() => {
    dispatch(
      SmartphoneSaleSliceReducer.actions.submitSmartphoneSaleLock({
        contractId,
        transactionalCreditEvaluationId: transactionalCreditEvaluationId!,
      })
    )
  }, [])

  useEffect(() => {
    if (smartphoneSaleLockConfirmed) {
      nextStep()
    }
  }, [smartphoneSaleLockConfirmed])

  useEffect(() => {
    if (confirmSmartphoneSaleLockError && !isConfirmingSmartphoneSaleLockLoading) {
      dispatch(
        SmartphoneSaleSliceReducer.actions.submitSmartphoneSaleLock({
          contractId: contractId,
          transactionalCreditEvaluationId: transactionalCreditEvaluationId!,
        })
      )
    }
  }, [confirmSmartphoneSaleLockError, isConfirmingSmartphoneSaleLockLoading])

  const handbookDownloadLink = getConfig().smartphoneSale.handbookLinksByBrand[
    smartphoneBrand ? smartphoneBrand : 'DEFAULT'
  ]
  if (isSubmittingSmartphoneSaleLockLoading && !confirmSmartphoneSaleLockError) {
    return (
      <LoadingContainer>
        <Circle />
        {<LoadingText>Registrando aparelho...</LoadingText>}
      </LoadingContainer>
    )
  }
  return (
    <MainContainer onKeyDown={handleKeyPressCancel}>
      <Container>
        <H5 style={{ fontFamily: 'Inter', fontSize: '18px' }}>
          Confirmar configuração do aparelho
        </H5>
        <>
          <Label style={{ fontFamily: 'Inter', fontSize: '14px' }}>
            É fundamental que todos os passos tenham sido corretamente executados conforme o manual
            abaixo.
          </Label>
        </>
        <Anchor
          style={{ width: '40%' }}
          icon={<Icon icon="download" color={green.primary} />}
          reverse={true}
          onClick={() => window.open(handbookDownloadLink, '_blank')}
          label={'Download do manual'}
          fontFamily="Inter"
          fontSize="14px"
          color={green.primary}
        />
        {confirmSmartphoneSaleLockError || submitSmartphoneSaleLockError ? (
          <InfoCard type="WARNING">
            <WarningIcon />
            <Column>
              <InfoCardText bold={true}>Configuração ainda não foi confirmada</InfoCardText>
              <InfoCardText>
                Não conseguimos confirmar a configuração do aparelho. Verifique se alguma etapa não
                foi realizada e tente novamente.
              </InfoCardText>
            </Column>
          </InfoCard>
        ) : (
          <InfoCard type="INFO">
            <InfoIcon />
            <Column>
              <InfoCardText>
                Em caso de erro no processo de configuração, cancele a operação, realize o reset de
                fábrica do aparelho e execute o procedimento novamente.
              </InfoCardText>
            </Column>
          </InfoCard>
        )}
      </Container>
      <PurchaseConfirmationButtons
        onConfirm={confirmSmartphoneSaleLock}
        onCancel={cancelPurchase}
        loading={isConfirmingSmartphoneSaleLockLoading || isSubmittingSmartphoneSaleLockLoading}
        confirmButtonLabel="Confirmar"
        cancelButtonLabel="Cancelar operação"
      />
    </MainContainer>
  )
}

const Label = styled.p`
  color: ${black.text2};
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  justify-content: flex-end;
  max-width: 500px;
  height: 100%;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Circle = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 128, 0, 0.2);
  border-top: 4px solid #008000;
  border-radius: 50%;
  animation: ${rotate} 2s linear infinite;
`

const LoadingText = styled.div`
  max-width: 160px;
  margin-top: 16px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${gray.scale800};
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`

export default SmartphoneLockConfiguration
