import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { IOperator } from '../../../services/bff/coordinator/dto/Operators.dto'
import {
  OperatorPerformance,
  OperatorPerformanceInfoResponse,
} from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import { Role } from '../../../common/constants'

interface SetOperatorActionPayload {
  id: string
  name: string
  document: string
  phoneNumber?: string
  role: Role
}

interface OperatorPixAccount {
  pixKeyType: string
  pixKey: string
}

export interface Operator {
  id: string
  name: string
  cpf: string
  phoneNumber?: string
  email?: string
  pixAccount?: OperatorPixAccount
  role: Role
}

export interface FetchOperatorPerformanceInfoPayload {
  operatorId?: string
}

interface OperatorState {
  operator?: Operator
  isFetchingOperator: boolean
  operatorPerformance?: OperatorPerformance
}

const initialState: OperatorState = {
  operator: undefined,
  isFetchingOperator: false,
  operatorPerformance: undefined,
}

export interface IFetchOperatorPayload {
  operatorId: string
}

const operatorSliceReducer = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    fetchOperator: (state, action: PayloadAction<IFetchOperatorPayload>) => {
      const { operatorId } = action.payload
      return {
        ...state,
        operatorId,
        isFetchingOperator: true,
      }
    },
    fetchOperatorSuccess: (state, action: PayloadAction<IOperator>) => {
      const operator = action.payload
      return {
        ...state,
        operator,
        isFetchingOperator: false,
      }
    },
    fetchOperatorError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        error,
        isFetchingOperator: false,
      }
    },
    fetchOperatorPerformanceInfo: (
      state,
      action: PayloadAction<FetchOperatorPerformanceInfoPayload>
    ) => {
      return {
        ...state,
        isFetchingHomePageInfo: true,
      }
    },
    fetchOperatorPerformanceInfoSuccess: (
      state,
      action: PayloadAction<OperatorPerformanceInfoResponse>
    ) => {
      const homePageInfo = action.payload
      return {
        ...state,
        isFetchingHomePageInfo: false,
        operatorPerformance: homePageInfo.operatorPerformance,
      }
    },
    fetchOperatorPerformanceInfoError: (state, action: PayloadAction<AxiosError>) => {
      const error = action.payload
      return {
        ...state,
        error,
        isFetchingHomePageInfo: false,
      }
    },
    setOperator: (state, action: PayloadAction<SetOperatorActionPayload>) => {
      return {
        ...state,
        operator: {
          id: action.payload.id,
          name: action.payload.name,
          phoneNumber: action.payload.phoneNumber,
          cpf: action.payload.document,
          role: action.payload.role,
        },
      }
    },
  },
})

export default operatorSliceReducer
