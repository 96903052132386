import { Button, H5, Icon } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { toaster } from '../../App'
import { PurchaseVerificationErrors } from '../../common/ErrorStrings'
import PurchaseVerificationSliceReducer from '../../redux/reducers/purchase-verification/purchase-verification.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import NumberFormat from 'react-number-format'
import { gray, green, red } from '../../ui/utils/_colors'
import { IPhoneCommunicationChannel } from '../../services/bff/originations/dto/PhoneVerificationRecord.dto'
import * as config from '../../config/config'
import { VerificationMethod } from '../../services/bff/originations/dto/verification.enum'
import analyticsService from '../../services/analytics/analytics.service'
import { AnalyticsEvents } from '../../services/analytics/events'
import {
  formatDDGPhoneNumber,
  formatCellphoneWithoutNinthDigit,
} from '../../cash-register/common/formatCellPhone'
import { TokenRetryButtons } from './components/TokenRetryButtons'

interface TokenVerificationModalProps {
  isOpen: boolean
  onCancel: () => void
  handleKeyPressCancel: (event: React.KeyboardEvent) => void
  onNextStep: () => void
  proposalId: string
  formattedCellphone: string
  confirmButtonLabel?: string
  cancelButtonLabel?: string
}

export const TokenVerificationModal: React.FC<TokenVerificationModalProps> = ({
  isOpen,
  onCancel,
  handleKeyPressCancel,
  onNextStep,
  proposalId,
  formattedCellphone,
  confirmButtonLabel = 'Finalizar',
  cancelButtonLabel = 'Cancelar',
}) => {
  const dispatch = useDispatch()
  const [verificationCode, setVerificationCode] = useState<string>('')
  const {
    isCreatingPurchaseVerification,
    createdPurchaseVerification,

    isVerifyingPurchaseVerification,
    isVerifyPurchaseVerificationError,
    isPurchaseVerificationVerified,
  } = useTypedSelector((state) => ({
    isCreatingPurchaseVerification: state.purchaseVerification.isCreatingPurchaseVerification,
    createdPurchaseVerification: state.purchaseVerification.createdPurchaseVerification,

    isVerifyingPurchaseVerification: state.purchaseVerification.isVerifyingPurchaseVerification,
    isVerifyPurchaseVerificationError: state.purchaseVerification.isVerifyPurchaseVerificationError,
    isPurchaseVerificationVerified: state.purchaseVerification.isPurchaseVerificationVerified,
  }))

  const onConfirmVerificationCode = () => {
    analyticsService.sendEvent(AnalyticsEvents.purchase_otp_verification)
    dispatch(
      PurchaseVerificationSliceReducer.actions.verifyPurchaseVerification({
        proposalId,
        purchaseCode: verificationCode,
      })
    )
  }

  const sendNewVerificationCode = (communicationChannel?: IPhoneCommunicationChannel) => {
    dispatch(
      PurchaseVerificationSliceReducer.actions.createPurchaseVerification({
        proposalId,
        communicationChannel,
      })
    )
  }

  useEffect(() => {
    if (isVerifyPurchaseVerificationError) {
      toaster.showErrorToast(PurchaseVerificationErrors.VERIFY_PURCHASE_CODE_VERIFICATION_ERROR)
    }
  }, [isVerifyPurchaseVerificationError])

  return (
    <Container onKeyDown={handleKeyPressCancel}>
      <Content>
        {createdPurchaseVerification?.verificationMethod == VerificationMethod.ANALYST_CALL ? (
          <>
            <Title>Estamos avaliando sua compra</Title>

            <SectionText>
              Em instantes um atendente entrará em contato pelo número
              <SectionTextBold> {formattedCellphone}</SectionTextBold>.
            </SectionText>
            <SectionTitle>Dúvidas? Fale conosco:</SectionTitle>
            <SectionText>
              {formatDDGPhoneNumber(config.UME_PHONES.customerService.phoneNumber)} ou WhatsApp:{' '}
              {formatCellphoneWithoutNinthDigit(config.UME_PHONES.customerService.whatsapp)}
            </SectionText>
          </>
        ) : (
          <>
            <Title>Verificação de compra</Title>
            <SectionText>
              Digite o código de verificação enviado para o número{' '}
              <SectionTextBold> {formattedCellphone}</SectionTextBold> para concluir a compra.
            </SectionText>
          </>
        )}

        <Section>
          <InputContainer>
            <NumberFormat
              id="sms-code-input"
              className={`bp3-input ${
                verificationCode && verificationCode.length < 6
                  ? 'bp3-intent-danger'
                  : 'bp3-intent-none'
              } `}
              placeholder="Código de Verificação"
              autoComplete="off"
              tabIndex={1}
              format="### ###"
              mask="_"
              value={verificationCode}
              disabled={isPurchaseVerificationVerified}
              onValueChange={(values) => setVerificationCode(values.value)}
            />
            <ConfirmButton
              style={{ width: '12em' }}
              loading={isVerifyingPurchaseVerification}
              intent="success"
              text={!isPurchaseVerificationVerified && 'Checar código'}
              onClick={onConfirmVerificationCode}
              disabled={isPurchaseVerificationVerified || isCreatingPurchaseVerification}
            >
              {isPurchaseVerificationVerified && <Icon icon="tick" />}
            </ConfirmButton>
          </InputContainer>
        </Section>

        {!isPurchaseVerificationVerified &&
          createdPurchaseVerification?.verificationMethod == VerificationMethod.OTP && (
            <TokenRetryButtons
              isOpen={isOpen}
              isCreatingPurchaseVerification={isCreatingPurchaseVerification}
              sendNewVerificationCode={sendNewVerificationCode}
            />
          )}

        <ButtonContainer>
          <ConfirmButton
            disabled={!isPurchaseVerificationVerified}
            intent="success"
            text={confirmButtonLabel}
            onClick={onNextStep}
          />
          <CancelButton intent="danger" text={cancelButtonLabel} onClick={onCancel} />
        </ButtonContainer>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  justify-content: flex-end;
  max-width: 500px;
`

const Content = styled.div`
  width: 100%;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: 'Inter';
  margin-bottom: 16px;
`

const Section = styled.div`
  margin-bottom: 24px;
`

const SectionTitle = styled(H5)`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-family: 'Inter';
  margin-bottom: 8px;
`

const SectionText = styled.p`
  color: ${gray.scale700};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Inter';
`

const SectionTextBold = styled.text`
  color: ${gray.scale700};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: 'Inter';
`

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  gap: 16px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 16px;
`

const ConfirmButton = styled(Button)`
  background: ${green.dark1} 
  color: white;
  border-radius: 4px;
  border: 1px ${green.dark2};

  &:hover {
    background-color: ${(props: any) => (props && props.disabled ? green.dark1 : green.dark2)}

  &:disabled {
    opacity: 0.4;
  }
`

const CancelButton = styled(Button)`
  background-color: ${red.light2}
  color: white;
  border-radius: 4px;
  border: 1px ${red.dark2};

  &:hover {
    background-color: ${red.dark2}
  }
`
